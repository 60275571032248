<template>
  <div
    class="ant-input-group ant-input-group-compact"
    :class="[$style.languageBox, hasError && form.sort ? $style.error : '']"
    ref="boxRef"
  >
    <a-select
      :class="[$style.left, hasError && form.sort ? $style.successColor : '']"
      v-model="form.sort"
      @change="event => handleChange(event, 'sort')"
    >
      <a-select-option
        :value="item.id"
        v-for="item in languages"
        :key="item.id"
        >{{ item.name }}</a-select-option
      >
    </a-select>
    <a-input
      :class="[
        $style.right,
        hasError && form.language ? $style.successColor : '',
      ]"
      v-model="form.language"
      @input="event => handleChange(event, 'language')"
    ></a-input>
  </div>
</template>
<script>
import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator';
import { languages } from './final';
@Component()
export default class language extends Vue {
  list = languages;

  @Model('change.value', { type: Array }) value;
  @Prop({ type: Array }) value;
  @Prop({ type: Array }) defaultValue;

  get languages() {
    if (this.defaultValue && this.defaultValue.length) {
      return this.defaultValue;
    } else {
      return this.list;
    }
  }

  form = {
    sort: '',
    language: '',
  };

  handleChange(event, type) {
    let result = [];
    if (type === 'sort') {
      result[0] = event;
      result[1] = this.form.language || '';
    } else {
      result[0] = this.form.sort || '';
      result[1] = event.target.value;
    }
    this.$emit('change.value', result);
    this.$emit('change', result);
    this.$emit('input', result);
    this.findClass();
  }

  /** @name 是否需要单项表单检查 */
  @Prop({ type: Boolean, default: false }) hasCheck;
  hasError = false;
  findClass() {
    if (!this.hasCheck) {
      return false;
    }
    setTimeout(() => {
      try {
        // 获取这个元素<div class="ant-form-item-control has-error"></div>的 classlist ,来比较
        const array = this.domClassList;
        const result = array.find(v => v === 'has-error');
        if (result) {
          this.hasError = true;
        } else {
          this.hasError = false;
        }
      } catch (error) {
        this.hasError = false;
        return false;
      }
    }, 20);
  }
  get domClassList() {
    try {
      let el = this.$refs.boxRef.parentNode;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const array = Array.from(el.classList);
        const result = array.find(v => v === 'ant-form-item-control');
        if (result) {
          break;
        } else {
          el = el.parentNode;
        }
      }
      return Array.from(el.classList);
    } catch (error) {
      return [];
    }
  }

  @Watch('value', { deep: true, immediate: true })
  onWatchValue(value) {
    if (!value) return false;
    this.form.sort = value.length > 0 ? value[0] : '';
    this.form.language = value.length > 1 ? value[1] : '';
  }
}
</script>
<style lang="less" module>
.error {
  :global {
    .ant-select-open .ant-select-selection {
      border-color: var(--primary-80) !important;
      box-shadow: 0 0 0 2px var(--primary-fade-20) !important;

      &:focus {
        box-shadow: 0 0 0 2px var(--primary-fade-20);
        border-color: var(--primary-80);
      }
      &:hover {
        border-color: var(--primary-80);
      }
    }

    .ant-select-focused .ant-select-selection {
      border-color: var(--primary-80) !important;
      box-shadow: 0 0 0 2px var(--primary-fade-20) !important;
    }
  }
}
.languageBox {
  display: flex !important;

  .left {
    flex: 1;
  }
  .right {
    flex: 2;
  }

  .successColor {
    border-color: var(--form-border);

    &:hover,
    &:focus,
    &:not([disabled]):hover {
      border-color: var(--primary-80);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--primary-fade-20);
      border-color: var(--primary-80);
    }

    :global {
      .ant-select-selection {
        border-color: var(--form-border);
        &:hover {
          border-color: var(--primary-80);
        }
      }
      .ant-select-arrow {
        color: var(--font-sub);
      }
    }
  }
}
</style>
