import request from '../request';
const serviceName = '/iot-kcloud/kolb';

/**
 * @name 参数管理列表 分页
 * @param { Object } data
 */
export function getList(data) {
  return request(`${serviceName}/properties/list`, {
    method: 'GET',
    body: data,
  });
}

/**
 * @name 参数管理列表 分页
 * @param { Object } data
 */
export function getListAll(data) {
  return request(`${serviceName}/properties/listAll`, {
    method: 'GET',
    body: data,
  });
}
/**
 * @name 新增产品参数
 * @param { Object } data
 */
export function insertParam(data) {
  return request(`${serviceName}/properties/insert`, {
    method: 'POST',
    body: data,
  });
}
/**
 * @name 删除参数
 */
export function deleteParam(id) {
  return request(`${serviceName}/properties/${id}`, {
    method: 'DELETE',
    closeMessage: true,
  });
}
/**
 * @name 编辑参数
 * @param { Object } data
 */
export function editParam(data) {
  return request(`${serviceName}/properties/edit`, {
    body: data,
    method: 'PUT',
  });
}
/**
 * @name 参数详情
 */
export function getParamDetail(id) {
  return request(`${serviceName}/properties/${id}`);
}
/**
 * @name 校验密码
 */
export function checkPwd(data) {
  return request(`${serviceName}/product/checkPassWord`, {
    method: 'POST',
    body: data,
  });
}
