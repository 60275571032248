<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item prop="languages" label="多语言">
      <a-language v-model="form.languages" />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ALanguage from '@/views/oven-control/product/components/customized/a-language/index.vue';
@Component({
  components: { 'a-language': ALanguage },
})
export default class AddEnumLanguage extends Vue {
  form = {
    languages: [],
  };
  types = [];
  rules = {
    languages: [
      {
        required: true,
        validator: this.languagesValidator,
        trigger: 'change',
      },
    ],
  };

  languagesValidator(rule, value, cb) {
    if (value.length === 0) {
      cb('多语言不能为空');
    } else {
      const every = value.every(item => item.language[0] && item.language[1]);
      if (!every) {
        cb('多语言请填写完整');
      } else {
        cb();
      }
    }
  }

  @Prop() detail;
  @Watch('detail', { immediate: true })
  onWatchDetail(value) {
    if (!value && value.length > 0) return false;
    this.form.languages = value;
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          resolve(this.form.languages);
        } else {
          reject(false);
        }
      });
    });
  }

  onChange() {
    this.form.value = '';
  }
}
</script>
