<template>
  <div :class="$style.form">
    <div :class="$style.menuBox">
      <div>
        <a-form-model ref="form" layout="vertical" :model="form" :rules="rules">
          <a-row :gutter="20">
            <a-col :span="10">
              <a-form-model-item label="菜单头图" prop="img">
                <ImagesUpload
                  v-model="form.img"
                  :maxCount="1"
                  @change="imgUploadChange"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="14">
              <a-form-model-item label="默认名称" prop="menuName">
                <a-input
                  v-model="form.menuName"
                  style="width: 240px"
                  @change="editFlag(true)"
                  :maxLength="32"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-if="showImgMenu" :gutter="20">
            <a-col :span="10">
              <a-form-model-item
                ref="imageUrlLeft"
                label="图片地址"
                prop="imageUrlLeft"
              >
                <div style="display:flex;align-items: center;">
                  <a-input
                    style="width: 24%"
                    v-model="form.imageUrlLeft"
                    @blur="
                      () => {
                        $refs.imageUrlLeft.onFieldBlur();
                      }
                    "
                    @change="
                      () => {
                        editFlag(true);
                        $refs.imageUrlLeft.onFieldChange();
                      }
                    "
                  />
                  <span style="margin: 0 10px">至</span>
                  <a-input
                    style="width: 24%"
                    v-model="form.imageUrlRight"
                    @blur="
                      () => {
                        $refs.imageUrlLeft.onFieldBlur();
                      }
                    "
                    @change="
                      () => {
                        editFlag(true);
                        $refs.imageUrlLeft.onFieldChange();
                      }
                    "
                  />
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :span="14">
              <a-form-model-item>
                <span slot="label">
                  <span>分辨率</span>
                  <a-tooltip title="建议压缩为不超过40kb的256色bmp图片">
                    <a-icon type="question-circle-o" />
                  </a-tooltip>
                </span>
                <div style="display: flex;align-items: center; width: 240px">
                  <a-input-number
                    :min="1"
                    :max="1080"
                    :disabled="true"
                    :default-value="150"
                  />
                  <span style="padding: 0 5px;">*</span>
                  <a-input-number
                    :min="1"
                    :max="1080"
                    :disabled="true"
                    :default-value="150"
                  />
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-if="showImgMenu" :gutter="20">
            <a-col :span="10">
              <a-form-model-item label="Logo图片">
                <a-input
                  style="width: 240px"
                  v-model="form.logoImage"
                  :disabled="true"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="14">
              <a-form-model-item>
                <span slot="label">
                  <span>分辨率</span>
                  <a-tooltip title="建议压缩为不超过40kb的256色bmp图片">
                    <a-icon type="question-circle-o" />
                  </a-tooltip>
                </span>
                <div style="display: flex;align-items: center; width: 240px">
                  <a-input-number
                    :min="1"
                    :max="1080"
                    :disabled="true"
                    :default-value="300"
                  />
                  <span style="padding: 0 5px;">*</span>
                  <a-input-number
                    :min="1"
                    :max="1080"
                    :disabled="true"
                    :default-value="300"
                  />
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-if="showImgMenu" :gutter="20">
            <a-col :span="10">
              <a-form-model-item label="节能图片">
                <a-input
                  style="width: 240px"
                  v-model="form.smallImage"
                  :disabled="true"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="14">
              <a-form-model-item>
                <span slot="label">
                  <span>分辨率</span>
                  <a-tooltip title="建议压缩为不超过40kb的256色bmp图片">
                    <a-icon type="question-circle-o" />
                  </a-tooltip>
                </span>
                <div style="display: flex;align-items: center; width: 240px">
                  <a-input-number
                    :min="1"
                    :max="1080"
                    :disabled="true"
                    :default-value="770"
                  />
                  <span style="padding: 0 5px;">*</span>
                  <a-input-number
                    :min="1"
                    :max="1080"
                    :disabled="true"
                    :default-value="390"
                  />
                </div>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-if="showLog || showWarnings" :span="10">
              <a-form-model-item label="本月日志" prop="relevantFile">
                <a-select
                  v-model="form.relevantFile"
                  placeholder="请选择文件"
                  allowClear
                  style="width: 240px"
                  @change="editFlag(true)"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in filelist"
                    :key="item.id"
                  >
                    {{ item.fileName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col v-if="showLog" :span="10">
              <a-form-model-item label="上月日志" prop="secondFiles">
                <a-select
                  v-model="form.secondFiles"
                  placeholder="请选择文件"
                  allowClear
                  style="width: 240px"
                  @change="editFlag(true)"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in filelist"
                    :key="item.id"
                  >
                    {{ item.fileName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col v-if="showFile" :span="10">
              <a-form-model-item label="关联文件" prop="relevantFile">
                <a-input
                  v-if="
                    menu !== MENU_TYPE.CUSTOMIZED &&
                      menu !== MENU_TYPE.DEVICE_STATUS
                  "
                  v-model="form.relevantFile"
                  :disabled="true"
                  style="width: 240px"
                />
                <a-select
                  v-if="
                    menu === MENU_TYPE.CUSTOMIZED ||
                      menu === MENU_TYPE.DEVICE_STATUS
                  "
                  v-model="form.relevantFile"
                  placeholder="请选择文件"
                  allowClear
                  style="width: 240px"
                  @change="editFlag(true)"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in filelist"
                    :key="item.id"
                  >
                    {{ item.fileName }}
                  </a-select-option>
                </a-select>
                <!-- <span
                  v-if="menu === 'CUSTOMIZED'"
                  :class="$style.setting"
                  style="margin-left: 10px"
                  >管理</span
                > -->
              </a-form-model-item>
            </a-col>
            <a-col v-if="showLink" :span="10">
              <a-form-model-item label="跳转链接">
                <a-input v-model="form.url" @change="editFlag(true)" />
              </a-form-model-item>
            </a-col>
            <a-col :span="14">
              <a-form-model-item label="可见范围" prop="visibleRange">
                <a-checkbox-group
                  v-model="form.visibleRange"
                  @change="editFlag(true)"
                >
                  <a-checkbox
                    v-for="(item, idx) in visibleRangeArr"
                    :value="item.value"
                    :key="idx"
                    :disabled="item.value === 'ADMIN'"
                    name="type"
                  >
                    {{ item.label }}
                  </a-checkbox>
                </a-checkbox-group>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row v-if="customSetting" :gutter="20">
            <a-col :span="10">
              <a-form-model-item label="菜单功能">
                <span :class="$style.setting" @click.stop="openSetting"
                  >设置</span
                >
                <span>当前包含{{ settingData.sum }}个参数控制项</span>
              </a-form-model-item>
            </a-col>
            <a-col :span="14">
              <a-form-model-item>
                <span slot="label">
                  <span>恢复默认</span>
                  <a-tooltip
                    title="开启后将会在每个自定义菜单底部配置“恢复默认设置”选项，点击后将当前配置恢复到参数默认数值，并在提交数据后执行同步到设备！"
                  >
                    <a-icon type="question-circle-o" />
                  </a-tooltip>
                </span>
                <a-switch
                  v-model="form.rollback"
                  checked-children="是"
                  un-checked-children="否"
                  @change="editFlag(true)"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col :span="10">
              <a-form-model-item label="多语言">
                <ALanguage v-model="form.language" @change="changeLanguage" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div :class="$style.button">
          <a-button type="primary" @click="saveAll">保存</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { createFormModal } from '@triascloud/x-components';
import CustomSetting from './custom-setting';
import ALanguage from '../a-language';
import ImagesUpload from '../../images-upload';
import { getMenuFileList, getIotOssHost } from '@/services/oven/product';
import { UploadService } from '@triascloud/services';

const MENU_TYPE = {
  /** @name 食谱设置 */
  RECIPE_SETTINGS: 'RECIPE_SETTINGS',
  /** @name 收藏管理 */
  COLLECT_MANAGE: 'COLLECT_MANAGE',
  /** @name 设备图库 */
  DEVICE_MAP_DEPOT: 'DEVICE_MAP_DEPOT',
  /** @name 升级备份 */
  UPGRADE_BACKUP: 'UPGRADE_BACKUP',
  /** @name 运行统计 */
  RUNNING_STATISTICS: 'RUNNING_STATISTICS',
  /** @name 操作指南 */
  OPERATION_GUIDE: 'OPERATION_GUIDE',
  /** @name 官网介绍 */
  OFFICIAL_WEBSITE: 'OFFICIAL_WEBSITE',
  /** @name 设备状态 */
  DEVICE_STATUS: 'DEVICE_STATUS',
  /** @name 设备日志 */
  DEVICE_LOGGER: 'DEVICE_LOGGER',
  /** @name 设备预警 */
  DEVICE_WARNING: 'DEVICE_WARNING',
  /** @name 自定义 */
  CUSTOMIZED: 'CUSTOMIZED',
};

@Component({
  components: {
    CustomSetting,
    ALanguage,
    ImagesUpload,
  },
})
export default class DefaultMenuForm extends Vue {
  @Prop({ type: Object, default: () => {} }) menuData;
  @Prop({ type: String, default: '' }) productId;

  MENU_TYPE = MENU_TYPE;

  form = {
    img: [],
    menuName: '食谱设置',
    imageUrlLeft: '',
    imageUrlRight: '',
    logoImage: 'logo.bmp',
    smallImage: 'eco.bmp',
    relevantFile: undefined,
    secondFiles: undefined,
    visibleRange: [],
    url: '',
    rollback: false,
    language: [],
  };
  rules = {
    img: [
      {
        required: true,
        message: '请上传菜单头图',
      },
    ],
    menuName: [
      {
        required: true,
        message: '请输入菜单名称',
      },
    ],
    relevantFile: [
      {
        required: true,
        message: '请选择关联文件',
      },
    ],
    visibleRange: [
      {
        required: true,
        message: '请至少选择一个可见范围',
      },
    ],
    imageUrlLeft: [
      {
        required: true,
        trigger: ['change', 'blur'],
        validator: (rule, value, callback) => {
          if (this.form.imageUrlLeft === '' || this.form.imageUrlRight === '') {
            callback(new Error('请输入完整的图片路径'));
          } else {
            callback();
          }
        },
      },
    ],
  };
  visibleRangeArr = [
    {
      value: 'NORMAL',
      label: '门店用户',
    },
    {
      value: 'SHOP_MANAGER',
      label: '工厂门店负责人',
    },
    {
      value: 'ENGINEER',
      label: '工程师',
    },
    {
      value: 'DEVELOPER',
      label: '开发人员',
    },
    {
      value: 'ADMIN',
      label: '管理人员',
    },
  ];
  async getFileList() {
    try {
      this.filelist = await getMenuFileList(this.productId);
    } catch {
      return false;
    }
  }
  filelist = [];
  menu = '';
  id = '';
  @Watch('menuData', { immediate: true, deep: true })
  async changeData(newVal, oldVal) {
    if (newVal !== oldVal && newVal.menuDetails) {
      this.id = newVal.id;
      this.menu = newVal.menu;
      const list = newVal.menuDetails;
      this.form.menuName = list.menuName;
      this.form.visibleRange = list.visibleRange;
      const ossPath = await this.changeOssToUrl(list.menuPicture);
      this.form.img = [
        {
          uid: -1,
          name: 'picture',
          status: 'done',
          url: ossPath[0],
          ossPath: list.menuPicture,
        },
      ];
      this.form.language = this.listByMap(list.multiLanguage);
      if (
        [MENU_TYPE.RECIPE_SETTINGS, MENU_TYPE.COLLECT_MANAGE].indexOf(
          newVal.menu,
        ) > -1
      ) {
        const idx = this.filelist.findIndex(v => v.id === list.associatedFiles);
        this.form.relevantFile = this.filelist[idx].fileName;
      }
      if (newVal.menu === MENU_TYPE.DEVICE_MAP_DEPOT) {
        this.form.imageUrlLeft = list.imageUrlLeft;
        this.form.imageUrlRight = list.imageUrlRight;
      }
      if (
        [MENU_TYPE.OPERATION_GUIDE, MENU_TYPE.OFFICIAL_WEBSITE].indexOf(
          newVal.menu,
        ) > -1
      ) {
        this.form.url = list.url;
      }
      if (this.menu === MENU_TYPE.CUSTOMIZED) {
        this.settingData = {
          menuFunction: list.menuFunction,
          sum: 0,
        };
        this.form.relevantFile =
          list.associatedFiles !== '' ? list.associatedFiles : undefined;
        list.menuFunction?.forEach(item => {
          this.settingData.sum += item.parameterList.length;
        });
        this.menulistParams = list.menuFunction;
      }
      if (this.menu === MENU_TYPE.DEVICE_STATUS) {
        this.form.relevantFile =
          list.associatedFiles !== '' ? list.associatedFiles : undefined;
      }
      if (this.menu === MENU_TYPE.DEVICE_LOGGER) {
        this.form.relevantFile =
          list.associatedFiles !== '' ? list.associatedFiles : undefined;
        this.form.secondFiles =
          list.secondFiles !== '' ? list.secondFiles : undefined;
      }
      if (this.menu === MENU_TYPE.DEVICE_WARNING) {
        this.form.relevantFile =
          list.associatedFiles !== '' ? list.associatedFiles : undefined;
      }
    }
  }
  mounted() {
    this.init();
    this.getFileList();
  }
  staticOssHead = '';
  async init() {
    try {
      this.staticOssHead = await getIotOssHost();
    } catch {
      return false;
    }
  }
  async changeOssToUrl(list) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const result = [];
    if (list.startsWith('common/static')) {
      const url = `${this.staticOssHead}/${list}`;
      result.push(url);
    } else {
      const url = await customService.getAuth(list);
      result.push(url);
    }
    return result;
  }

  get showLog() {
    return this.menu === MENU_TYPE.DEVICE_LOGGER;
  }
  get showWarnings() {
    return this.menu === MENU_TYPE.DEVICE_WARNING;
  }

  fileMenu = [
    MENU_TYPE.RECIPE_SETTINGS,
    MENU_TYPE.COLLECT_MANAGE,
    MENU_TYPE.CUSTOMIZED,
    MENU_TYPE.DEVICE_STATUS,
  ];
  get showFile() {
    const menu = this.menu;
    return this.fileMenu.indexOf(menu) > -1;
  }
  get customSetting() {
    return this.menu === MENU_TYPE.CUSTOMIZED;
  }
  linkMenu = [MENU_TYPE.OPERATION_GUIDE, MENU_TYPE.OFFICIAL_WEBSITE];
  get showLink() {
    const menu = this.menu;
    return this.linkMenu.indexOf(menu) > -1;
  }
  get showImgMenu() {
    return this.menu === MENU_TYPE.DEVICE_MAP_DEPOT;
  }
  settingData = {
    sum: 0,
    menuFunction: [],
  };
  menulistParams = [];
  async openSetting() {
    const result = await createFormModal(
      () => (
        <CustomSetting
          productId={this.productId}
          settingData={this.menulistParams}
        />
      ),
      {
        width: '1000px',
        title: '菜单功能添加',
      },
    );
    if (result) {
      this.settingData = {
        sum: result.sum,
        menuFunction: result.menuFunction,
      };
    }
    this.editFlag(true);
  }
  imgUploadChange() {
    this.$emit('editFlag', true);
  }
  editFlag(f) {
    this.$emit('editFlag', f);
  }
  languages = {};
  listByMap(obj) {
    if (obj === null || obj === undefined || Object.keys(obj).length === 0) {
      return [];
    }
    const array = [];
    Object.keys(obj).forEach(v => {
      array.push({
        language: [v, obj[v]],
      });
    });
    return array;
  }
  formatList(list = [], type = 'language') {
    const map = {};
    list.forEach(item => {
      map[item[type][0]] = item[type][1];
    });
    return map;
  }
  changeLanguage(v) {
    this.languages = this.formatList(v);
    this.editFlag(true);
  }
  saveAll() {
    this.$refs.form.validate(valid => {
      if (valid) {
        this.editFlag(false);
        const list = this.menuData.menuDetails;
        const params = {
          id: this.id,
          menu: this.menu,
          name: this.form.menuName,
          menuDetails: {
            menuName: this.form.menuName,
            menuPicture: this.form.img[0].ossPath,
            multiLanguage: this.languages,
            visibleRange: this.form.visibleRange,
          },
        };
        if (
          [MENU_TYPE.RECIPE_SETTINGS, MENU_TYPE.COLLECT_MANAGE].indexOf(
            this.menu,
          ) > -1
        ) {
          params.menuDetails.associatedFiles = list.associatedFiles;
        }
        if (this.menu === MENU_TYPE.DEVICE_MAP_DEPOT) {
          params.menuDetails.imageUrlLeft = this.form.imageUrlLeft;
          params.menuDetails.imageUrlRight = this.form.imageUrlRight;
          params.menuDetails.imageResolution = list.imageResolution;
          params.menuDetails.logoImage = list.logoImage;
          params.menuDetails.logoResolution = list.logoResolution;
          params.menuDetails.smallImage = list.smallImage;
          params.menuDetails.smallResolution = list.smallResolution;
        }
        if (
          [MENU_TYPE.OPERATION_GUIDE, MENU_TYPE.OFFICIAL_WEBSITE].indexOf(
            this.menu,
          ) > -1
        ) {
          params.menuDetails.url = this.form.url;
        }
        if (this.menu === MENU_TYPE.CUSTOMIZED) {
          params.menuDetails.rollback = this.form.rollback;
          params.menuDetails.associatedFiles = this.form.relevantFile;
          params.menuDetails.menuFunction = this.settingData.menuFunction;
        }
        if (this.menu === MENU_TYPE.DEVICE_STATUS) {
          params.menuDetails.associatedFiles = this.form.relevantFile;
        }
        if (this.menu === MENU_TYPE.DEVICE_LOGGER) {
          params.menuDetails.associatedFiles = this.form.relevantFile;
          params.menuDetails.secondFiles = this.form.secondFiles;
        }
        if (this.menu === MENU_TYPE.DEVICE_WARNING) {
          params.menuDetails.associatedFiles = this.form.relevantFile;
        }
        this.$emit('editFlag', false);
        this.$emit('saveAll', params);
      } else {
        this.editFlag(true);
        return false;
      }
    });
  }
}
</script>

<style lang="less" module>
.form {
  border: 1px solid var(--border);
  box-shadow: 0 0 6px 0 var(--shadow);
  border-radius: 4px;
  padding: 20px 0;
  overflow-y: auto;
  height: 580px;
  position: relative;
  .img {
    width: 120px;
    height: 120px;
  }
  .setting {
    color: var(--primary);
    margin-right: 10px;
    cursor: pointer;
  }
  .button {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
.menuBox {
  height: 538px;
  padding: 0 20px;
  overflow-y: auto;
}
</style>
