<template>
  <div>
    <a-upload
      list-type="picture-card"
      :file-list="images"
      @preview="handlePreview"
      @change="handleImgsChange"
      :customRequest="customUpload"
      accept="image/*"
      :disabled="isDisable"
    >
      <div v-if="images.length < maxCount && !isDisable">
        <a-icon type="plus" />
      </div>
    </a-upload>
    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="handlePreviewCancel"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal
      :visible="imageEditVisible"
      :maskClosable="false"
      @ok="handleImgResize"
      @cancel="handleCancel"
      :width="530"
    >
      <div style="min-height:300px">
        <cropper
          ref="cropper"
          :src="previewImage"
          :stencil-size="{
            width: 300,
            height: 300,
          }"
          :stencil-props="{
            handlers: {},
            movable: false,
            resizable: false,
            aspectRatio: 1,
          }"
          image-restriction="stencil"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
import { Component, Prop, Vue, Model, Watch } from 'vue-property-decorator';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { UploadService } from '@triascloud/services';

@Component({ components: { Cropper } })
export default class ImagesUpload extends Vue {
  @Model('change.value') value;
  @Prop({ type: Array }) value;

  images = [];
  @Watch('value', { deep: true, immediate: true })
  onValue(value) {
    this.images = value || [];
  }

  @Prop({ type: Number, default: 3 }) maxCount;
  @Prop({ type: String, default: '' }) pkId;
  @Prop({ type: Boolean, default: false }) isDisable;

  canEdit = true;
  imageEditVisible = false;
  imageUrl = '';

  previewImage = '';
  previewVisible = false;

  getBase64(img) {
    const reader = new FileReader();
    return new Promise(resolve => {
      reader.addEventListener('load', () => resolve(reader.result));
      reader.readAsDataURL(img);
    });
  }
  async handlePreview(file, type) {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }
    this.previewImage = file.preview || file.url;
    if (type === 'edit') {
      this.imageEditVisible = true;
    } else {
      this.previewVisible = true;
    }
  }
  customUpload() {}
  async uploadImg(file) {
    const files = file.originFileObj;
    const uploadPath = `${this.pkId}/kolb/pictures`;
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    return await customService.upload(files, uploadPath, () => {}, false);
  }
  async handleImgsChange({ fileList }) {
    if (fileList.length > this.images.length) {
      let file = fileList.pop();
      fileList.push(file);
      this.images = fileList;
      await this.handlePreview(file, 'edit');
    } else {
      this.images = fileList;
      this.images.forEach((i, index) => {
        const flag = fileList.findIndex(v => v.uid === i.uid);
        if (flag === -1) {
          this.images.splice(index, 1);
        }
      });
    }
    this.$emit('change.value', this.images);
    this.$emit('change', this.images);
    this.$emit('input', this.images);
  }
  handlePreviewCancel() {
    this.previewVisible = false;
  }
  handleCancel() {
    this.imageEditVisible = false;
    this.images.pop();
  }
  async handleImgResize() {
    const { canvas } = this.$refs.cropper.getResult();
    let previewImage = canvas.toDataURL();
    let file = this.images.pop();
    file.preview = previewImage;
    file.originFileObj = this.dataToBlob(previewImage, file.name);
    delete file.url;
    delete file.thumbUrl;
    this.imageEditVisible = false;
    file.ossPath = await this.uploadImg(file);
    file.status = 'done';
    this.images.push(file);
  }
  dataToBlob(data, fileName) {
    let fileType = data.split(',')[0].match(/:(.*?);/)[1];
    data = data.split(',')[1];
    data = window.atob(data);
    const ia = new Uint8Array(data.length);
    for (let i = 0; i < data.length; i++) {
      ia[i] = data.charCodeAt(i);
    }
    // canvas.toDataURL 返回的默认格式就是 image/png
    let newBlob = new Blob([ia], {
      type: fileType,
    });
    newBlob.lastModifiedDate = new Date();
    newBlob.name = fileName;
    return newBlob;
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-upload-select {
  background: var(--block-bg);
}
</style>
