<template>
  <div :class="$style.box">
    <div :class="$style.headerBox">
      <span :class="$style.imageBox">
        <x-oss-image
          basePath="/oss/iot/oss"
          :ossPath="form.imgSrc"
          @click="v => imageClick(v, record.picture)"
          :class="$style.image"
        />
      </span>
      <div :class="$style.infoRight">
        <h1 :class="$style.title">{{ form.identification }}</h1>
        <div :class="$style.label">
          <span :class="$style.line">
            产品分类：<span :class="$style.valueLabel">
              {{ form.productSign }}
            </span>
          </span>
          <span :class="$style.line">
            设备数：<span :class="$style.valueLabel">
              {{ form.deviceCount }} </span
            ><span :class="$style.otherBtn" @click="toDevicePage">
              前往管理
            </span>
          </span>
          <span :class="$style.line">
            软件版本：<span :class="$style.valueLabel">{{ form.version }}</span>
          </span>
          <span :class="$style.line">
            允许上架：
            <span>
              <a-switch
                :checked="switchs"
                checked-children="允许"
                un-checked-children="不许"
                @change="changeSwitch"
            /></span>
          </span>
        </div>
      </div>
    </div>
    <div>
      <a-tabs :default-active-key="activeTab" @tabClick="tabClick">
        <a-tab-pane key="1" tab="基础设置">
          <BaseSettings
            v-if="activeTab === '1'"
            :productId="productId"
            @refreshInfo="getBaseInfo"
          />
        </a-tab-pane>
        <a-tab-pane key="2" tab="食谱设置">
          <RecipeSetting v-if="activeTab === '2'" :productId="productId" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="菜单管理">
          <MenuManagement v-if="activeTab === '3'" :productId="productId" />
        </a-tab-pane>
        <a-tab-pane key="4" tab="参数管理">
          <Params :productId="productId" v-if="activeTab === '4'" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import Params from './params';
import MenuManagement from './menu-management';
import RecipeSetting from './recipe-setting';
import BaseSettings from './base-settings';
import { getProductBaseCfg, upDownProduct } from '@/services/oven/product';
import { createModal } from '@triascloud/x-components';
import Tip from '@/components/tip';
import { Button } from 'ant-design-vue';

@Component({
  components: {
    Params,
    MenuManagement,
    RecipeSetting,
    BaseSettings,
  },
})
export default class Customized extends Vue {
  @Prop({ type: String, default: '' }) productId;
  activeTab = '1';
  switchs = false;
  tabClick(v) {
    this.activeTab = v;
  }
  mounted() {
    this.getBaseInfo();
  }
  form = {
    identification: '',
    productSign: '',
    version: '',
    deviceCount: '',
    imgSrc: '',
  };
  defaultSwitches = false;
  async getBaseInfo() {
    try {
      const list = await getProductBaseCfg(this.productId);
      this.list = list;
      this.form.identification = list.identification;
      this.form.productSign = list.groupName;
      this.form.version = list.softwareVersion;
      this.form.deviceCount = list.count;
      this.switchs = list.state !== 'DEV';
      this.defaultSwitches = list.state !== 'DEV';
      this.form.imgSrc = list.picture[0];
    } catch {
      return false;
    }
  }
  toDevicePage() {
    const type = this.form.version;
    this.$router.push({
      path: '/oven/device',
      query: {
        productVersion: type,
      },
    });
  }
  switchModel = null;
  async changeSwitch(v) {
    const message = v
      ? '允许上架！待商家上架后即可使用此产品'
      : '不论上架是否上架此产品均会被强行下架，已绑定设备的客户将无法看到此设备，继续执行操作';
    this.switchModel = await createModal(
      () => (
        <Tip>
          <span slot="txt">{`${message}`}</span>
        </Tip>
      ),
      {
        title: '重要提示',
        width: '442px',
        footer: h => [
          h(
            Button,
            {
              on: {
                click: () => this.cancelSwitch(),
              },
            },
            '取消',
          ),
          h(
            Button,
            {
              props: { type: 'primary' },
              on: {
                click: () => this.confirmSwitch(v),
              },
            },
            '好的，我已知晓',
          ),
        ],
      },
    );
  }
  cancelSwitch() {
    this.switchModel?.handleClose();
    this.switchs = this.defaultSwitches;
  }
  async confirmSwitch(v) {
    const state = v ? 'WAIT_UP_SHELF' : 'DEV';
    const msg = v ? '允许上架成功' : '不允许上架成功';
    const params = {
      id: this.productId,
      state,
    };
    try {
      await upDownProduct(params);
      this.$message.success(msg);
      this.switchModel?.handleClose();
      this.switchs = v;
    } catch {
      return false;
    }
  }
}
</script>
<style lang="less" module>
.box {
  .headerBox {
    border: 1px solid var(--border);
    box-shadow: 0 0 6px 0 var(--shadow);
    border-radius: 4px;
    display: flex;
    padding: 20px;
    .imageBox {
      .image {
        width: 60px;
        height: 60px;
        border-radius: 4px;
      }
    }
    .infoRight {
      margin-left: 20px;
      .title {
        font-size: 16px;
      }
      .label {
        font-size: 12px;
        color: var(--font-sub);
        .valueLabel {
          color: var(--font);
        }
        .otherBtn {
          color: var(--font-active);
          margin-left: 8px;
          cursor: pointer;
        }
        .line + .line {
          margin-left: 40px;
        }
      }
    }
  }
}
</style>
