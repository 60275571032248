<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item prop="type" label="自动填充">
      <a-radio-group v-model="form.type" @change="onChange">
        <a-radio value="ONE">
          固定值
        </a-radio>
        <a-radio value="MULTI">
          可选值
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model label="">
      <a-input v-model="form.value" :maxLength="form.type === 'ONE' ? 4 : 16" />
    </a-form-model>
    <a-form-model-item label="填充位置" prop="place">
      <a-radio-group v-model="form.place">
        <a-radio value="BEFORE">
          输入值之前
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class SettingForm extends Vue {
  form = {
    type: 'ONE',
    value: '',
    place: 'BEFORE',
  };
  types = [];
  rules = {
    type: [
      {
        required: true,
        message: '自动填充不能为空',
      },
    ],
  };

  @Prop() detail;
  @Watch('detail', { immediate: true })
  onWatchDetail(value) {
    if (!value || (value && !value.value)) return false;
    this.form.value = value.value;
    this.form.place = value.place;
    this.form.type = value.type;
  }
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          resolve(this.form);
        } else {
          reject(false);
        }
      });
    });
  }

  onChange() {
    this.form.value = '';
  }
}
</script>
