<template>
  <div>
    <a-row :gutter="20">
      <a-col :span="8">
        <div :class="$style.card">
          <div :class="$style.addItem" @click.stop="addMenuItem">
            <a-icon :class="$style.addIcon" type="plus" />
            <span>添加自定义菜单</span>
          </div>
          <div ref="card" :class="$style.menuBox">
            <draggable
              tag="div"
              v-model="menuList"
              v-bind="viewOptions"
              chosen-class="chosen"
              handle=".iconMove"
            >
              <div
                v-for="(item, index) in menuList"
                :key="index"
                :class="[
                  $style.menuItem,
                  currentMenuIdx === index ? $style.activeMenuItem : '',
                ]"
                @click.stop="handleMenu(item, index)"
              >
                <div :class="$style.leftItem">
                  <x-icon
                    class="iconMove"
                    :class="$style.moveIcon"
                    type="tc-icon-drag-handler"
                  />
                  <x-oss-image
                    basePath="/oss/iot/oss"
                    :ossPath="item.menuDetails.menuPicture"
                    :class="$style.img"
                  />
                  <span :class="$style.text" :title="item.name">{{
                    item.name
                  }}</span>
                </div>
                <x-icon
                  v-if="item.menu === 'CUSTOMIZED'"
                  :class="$style.deleteIcon"
                  type="tc-icon-delete"
                  @click.stop="deleteMenuItem(item)"
                />
              </div>
            </draggable>
          </div>
        </div>
      </a-col>
      <a-col :span="16">
        <component
          :is="currentComponent"
          :productId="productId"
          :menuData="menuData"
          @saveAll="saveAll"
          @editFlag="getEditFlag"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import DefaultMenuForm from './default-menu-form';
import { v4 as uuidv4 } from 'uuid';
import { getMenuCfg, saveMenuCfg } from '@/services/oven/product';

@Component({
  components: {
    Draggable,
    DefaultMenuForm,
  },
})
export default class MenuManagement extends Vue {
  @Prop({ type: String, default: '' }) productId;
  currentMenuIdx = 0;
  viewOptions = {
    animation: 300,
    group: 'viaCamera',
    ghostClass: 'ghost',
    scroll: true,
  };
  currentComponent = 'DefaultMenuForm';
  editFlag = false;
  getEditFlag(v) {
    this.editFlag = v;
  }

  mounted() {
    this.getMenuList();
  }

  menuList = [];
  menuData = {};
  async getMenuList() {
    try {
      this.menuList = await getMenuCfg(this.productId);
    } catch {
      return false;
    }
    this.menuData = this.menuList[this.currentMenuIdx];
  }
  handleMenu(item, index) {
    if (this.editFlag) {
      this.$message.error('请先保存');
      return;
    }
    const list = this.menuList;
    this.currentMenuIdx = index;
    this.currentComponent = 'DefaultMenuForm';
    this.menuData = list[index];
  }
  addMenuItem() {
    if (this.editFlag) {
      this.$message.error('请先保存');
      return;
    }
    const list = this.menuList;
    const idx = this.menuList.length - 6;
    if (list.length === 17) {
      this.$message.warning('自定义菜单最多添加10个');
      return;
    }
    const uuid = uuidv4();
    const menuItem = {
      id: uuid,
      name: '自定义菜单' + idx,
      menu: 'CUSTOMIZED',
      menuDetails: {
        menuName: '自定义菜单' + idx,
        visibleRange: ['ENGINEER', 'DEVELOPER', 'ADMIN'],
        menuFunction: [],
        menuPicture: 'common/static/images/xitogshezhi.png',
        rollback: false,
      },
    };
    this.menuList.push(menuItem);
    this.currentMenuIdx = this.menuList.length - 1;
    this.menuData = this.menuList[this.currentMenuIdx];
    this.currentComponent = 'DefaultMenuForm';
    const dom = this.$refs.card;
    this.$nextTick(() => {
      dom.scrollTop = dom.scrollHeight;
    });
  }
  async deleteMenuItem(item) {
    const list = this.menuList;
    const idx = list.findIndex(v => v.id === item.id);
    list.splice(idx, 1);
    if (this.currentMenuIdx === idx) {
      if (idx === list.length) {
        this.currentMenuIdx = idx - 1;
      } else {
        this.currentMenuIdx = idx;
      }
      const itemAfterDelete = list[this.currentMenuIdx];
      this.currentComponent = 'DefaultMenuForm';
      this.menuData = itemAfterDelete;
    } else if (this.currentMenuIdx > idx) {
      this.currentMenuIdx--;
    }
  }
  async saveAll(data) {
    const idx = this.menuList.findIndex(v => v.id === data.id);
    this.menuList.splice(idx, 1, data);
    const params = {
      id: this.productId,
      kolbMenuManagementJsons: this.menuList,
    };
    try {
      await saveMenuCfg(params);
      await this.getMenuList();
      this.$message.success('保存成功');
      this.currentComponent = 'DefaultMenuForm';
      this.menuData = this.menuList[this.currentMenuIdx];
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.card {
  border: 1px solid var(--border);
  box-shadow: 0 0 6px 0 var(--shadow);
  border-radius: 4px;
  padding: 20px 0;
  height: 580px;
  .menuItem {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: var(--block-bg);
    border: solid 1px var(--form-border);
    margin-bottom: 10px;
    cursor: pointer;
    justify-content: space-between;
    &:hover {
      background-color: var(--inline-menu-active);
      .deleteIcon {
        opacity: 1;
      }
    }
    .leftItem {
      display: flex;
      align-items: center;
    }
    .chosen {
      border: solid 2px #3089dc !important;
    }
    .moveIcon {
      cursor: move;
      font-size: 24px;
      margin-right: 10px;
    }
    .deleteIcon {
      opacity: 0;
      font-size: 18px;
      margin-right: 20px;
      cursor: pointer;
      color: var(--primary);
    }
  }
  .activeMenuItem {
    background-color: var(--inline-menu-active);
    color: var(--font-active);
  }
  .img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }
  .text {
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 385px;
  }
}
.addItem {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: var(--block-bg);
  border: solid 1px var(--form-border);
  justify-content: center;
  cursor: pointer;
  margin: 0 20px;
  .addIcon {
    color: var(--primary);
    margin-right: 10px;
  }
}
.menuBox {
  margin-top: 10px;
  height: 500px;
  padding: 0 20px;
  overflow-y: auto;
}
</style>
