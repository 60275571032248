import request from '../request';

const serverName = '/iot-kcloud/kolb';

// 获取当前产品列表
export function getProductList(data) {
  return request(`${serverName}/product/list`, {
    method: 'POST',
    body: data,
  });
}

// 新增产品
export function addProduct(data) {
  return request(`${serverName}/product/insert`, {
    method: 'POST',
    body: data,
  });
}

// 获取产品分类和版本信息
export function getProductInfo() {
  return request(`${serverName}/equipment/listByTenant`, {
    method: 'GET',
  });
}

// 删除产品
export function deleteProduct(id) {
  return request(`${serverName}/product/${id}`, {
    method: 'DELETE',
  });
}

// 删除产品
export function copyProduct(id) {
  return request(`${serverName}/product/copy/${id}`, {
    method: 'POST',
  });
}

// 编辑产品
export function updateProduct(data) {
  return request(`${serverName}/product/edit`, {
    method: 'PUT',
    body: data,
  });
}

// 获取产品基础设置
export function getProductBaseCfg(id) {
  return request(`${serverName}/product/baseDetail/${id}`, {
    method: 'GET',
  });
}
// 保存产品基础设置
export function saveProductBaseCfg(data) {
  return request(`${serverName}/product/base`, {
    method: 'PUT',
    body: data,
  });
}

// 获取食谱设置
export function getRecipeCfg(id) {
  return request(`${serverName}/product/recipeDetail/${id}`, {
    method: 'GET',
  });
}

// 保存食谱设置
export function saveRecipeCfg(data) {
  return request(`${serverName}/product/recipe`, {
    method: 'PUT',
    body: data,
  });
}

// 获取菜单设置
export function getMenuCfg(id) {
  return request(`${serverName}/product/menuDetail/${id}`, {
    method: 'GET',
  });
}
// 保存菜单设置
export function saveMenuCfg(data) {
  return request(`${serverName}/product/menu`, {
    method: 'PUT',
    body: data,
  });
}

// 上下架
export function upDownProduct(data) {
  return request(`${serverName}/product/state`, {
    method: 'PUT',
    body: data,
  });
}
// 获取自定义菜单 关联文件的列表
export function getMenuFileList(id) {
  return request(`${serverName}/files/productList/${id}`, {
    method: 'GET',
  });
}

// 获取oss前缀
export function getIotOssHost() {
  return request(`/oss/iot/oss/host`, {
    method: 'GET',
  });
}
