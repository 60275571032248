<template>
  <div>
    <a-form-model ref="form" layout="vertical" :model="form">
      <a-form-model-item label="参数集合名称" prop="parameterName">
        <a-input v-model="form.parameterName" :maxLength="32" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class AddParameterGroup extends Vue {
  @Prop({ type: String, default: '' }) editName;
  @Prop({ type: Array, default: [] }) nameList;
  form = {
    parameterName: '',
  };
  // rules = {
  //   parameterName: [
  //     {
  //       trigger: ['change', 'blur'],
  //       validator: (rule, value, callback) => {
  //         if (this.nameList.indexOf(value) > -1) {
  //           throw new Error('集合名称已存在');
  //         } else {
  //           callback();
  //         }
  //       },
  //     },
  //   ],
  // };
  mounted() {
    if (this.editName) {
      this.form.parameterName = this.editName;
    }
  }

  getValue() {
    return this.form;
  }
}
</script>

<style scoped></style>
