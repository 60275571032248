<script>
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ALanguage from '../a-language';
import EnumNumberRange from './enum-number-range';
import NumberRange from '../x-range/x-range';
import BooleanNumberRange from './boolean-number-range';
import { DATATYPE, types } from './final';
import { createFormModal } from '@triascloud/x-components';
import SettingForm from './setting-form.vue';

/**
 * @name 中文或者字符长度
 * @param txt {string} 文本
 * @param length {number} 限制长度
 */
const limitTxtLength = (txt, length) => {
  const reg = /[^\u0000-\u00ff]/g;
  if (txt.replace(reg, 'aa').length > length) {
    return false;
  } else {
    return true;
  }
};

/** @name 多语言map转换为数组结构 */
export const listByMap = (item, type = 'language') => {
  const array = [];
  if (item) {
    Object.keys(item).forEach(v => {
      const obj = {};
      obj[type] = [v, item[v]];
      array.push(obj);
    });
  }
  return array;
};
/** @name 多语言数组转换为map结构 */
export const formatList = (list = [], type = 'language') => {
  const map = {};
  list.forEach(item => {
    map[item[type][0]] = item[type][1];
  });
  return map;
};
/** @name 枚举多语言格式化为map */
const languageFormat = (list = [], type = 'number') => {
  const map = {};
  list.forEach(item => {
    map[item[type][0]] = item['multiLanguage'];
  });
  return map;
};
/** @name 枚举多语言格式化为数组 */
const languageForList = (item, type = 'language') => {
  const array = [];
  Object.keys(item.enumValue).forEach(v => {
    const obj = {};
    obj[type] = [v, item.enumValue[v]];
    if (item.multiLanguage) {
      obj['multiLanguage'] = item.multiLanguage[v];
    }
    array.push(obj);
  });
  return array;
};

@Component({
  components: {
    'a-language': ALanguage,
    'a-enum-number-range': EnumNumberRange,
    'a-number-range': NumberRange,
    'a-boolean-number-range': BooleanNumberRange,
  },
})
export default class ParamForm extends Vue {
  @Prop({ type: Object, default: () => {} }) detail;
  created() {
    this.$delete(this.form, 'range');
  }

  form = {
    name: '',
    caption: '',
    mark: '',
    type: undefined,
    remark: '',
    languages: [],
  };

  @Watch('detail', { immediate: true })
  onWatchDetail(value) {
    if (!value) return false;
    this.form.type = value.dataType.dataType;
    this.onChangeType(this.form.type);
    this.formatDataByType(value.dataType.dataType);
    this.form.name = value.parameterName;
    this.form.caption = value.promptDescription;
    this.form.mark = value.identifier;
    this.form.remark = value.remark;
    this.form.languages = listByMap(value.multiLanguage, 'language');
  }
  formatDataByType(type) {
    const specs = this.detail.dataType.specs;
    switch (type) {
      case DATATYPE.int:
        this.form.default = specs.defaultValue;
        this.form.numberRange = [specs.min, specs.max];
        this.form.readonlyType = specs.readWriteMethod;
        this.form.readonly = specs.readWriteType;
        this.form.step = specs.step;
        this.form.unit = specs.unit;
        this.form.autoSupllyConfig = specs.autoSupllyConfig;
        break;
      case DATATYPE.float:
        this.form.default = specs.defaultValue;
        this.form.numberRange = [specs.min, specs.max];
        this.form.readonlyType = specs.readWriteMethod;
        this.form.readonly = specs.readWriteType;
        this.form.step = specs.step;
        this.form.unit = specs.unit;
        break;
      case DATATYPE.boolean:
        this.form.default = specs.defaultValue;
        this.form.readonlyType = specs.readWriteMethod;
        this.form.readonly = specs.readWriteType;
        this.form.numberRange = [specs.boolValue[0], specs.boolValue[1]];
        break;
      case DATATYPE.enum:
        this.form.default = specs.defaultValue;
        this.form.readonly = specs.readWriteType;
        this.form.unit = specs.unit;
        this.form.numberRange = languageForList(specs, 'number');
        break;
      case DATATYPE.text:
        this.form.default = specs.defaultValue;
        this.form.readonly = specs.readWriteType;
        this.form.numberRange = specs.textLength;
        break;
      default:
        break;
    }
  }

  onChangeType(value) {
    this.updateFormScheme(value);
  }
  updateFormScheme(value) {
    this.deleteFormScheme();
    switch (value) {
      case DATATYPE.int:
      case DATATYPE.float:
        this.addFormSchemeForIntOrFloat();
        break;
      case DATATYPE.enum:
        this.addFormSchemeForEnum();
        break;
      case DATATYPE.boolean:
        this.addFormSchemeForBoolean();
        break;
      case DATATYPE.text:
        this.addFormSchemeForText();
        break;
      default:
        this.deleteFormScheme();
        break;
    }
  }
  addFormSchemeForIntOrFloat() {
    this.$set(this.form, 'numberRange', []);
    this.$set(this.form, 'default', undefined);
    this.$set(this.form, 'unit', undefined);
    this.$set(this.form, 'readonly', undefined);
    this.$set(this.form, 'readonlyType', undefined);
    this.$set(this.form, 'step', undefined);
  }
  addFormSchemeForEnum() {
    this.$set(this.form, 'numberRange', [{ numbers: [], multiLanguage: [] }]);
    this.$set(this.form, 'default', undefined);
    this.$set(this.form, 'unit', undefined);
    this.$set(this.form, 'readonly', undefined);
  }
  addFormSchemeForBoolean() {
    this.$set(this.form, 'numberRange', []);
    this.$set(this.form, 'default', undefined);
    this.$set(this.form, 'readonly', undefined);
    this.$set(this.form, 'readonlyType', undefined);
  }
  addFormSchemeForText() {
    this.$set(this.form, 'numberRange', undefined);
    this.$set(this.form, 'default', undefined);
    this.$set(this.form, 'readonly', undefined);
  }
  deleteFormScheme() {
    this.$delete(this.form, 'numberRange');
    this.$delete(this.form, 'default');
    this.$delete(this.form, 'unit');
    this.$delete(this.form, 'readonly');
    this.$delete(this.form, 'readonlyType');
    this.$delete(this.form, 'step');
  }

  get rules() {
    let result = {};
    const base = {
      name: [
        {
          required: true,
          validator: (rule, value, callback) =>
            this.checkLength(
              rule,
              value,
              callback,
              '参数名称不能为空',
              '不得超过16汉字或32字符长度',
              32,
            ),
          trigger: 'change',
        },
      ],
      mark: [
        {
          required: true,
          validator: (rule, value, callback) =>
            this.checkLength(
              rule,
              value,
              callback,
              '参数标识不能为空',
              '不得超过32字符长度',
              32,
            ),
          trigger: 'change',
        },
      ],
      type: [
        {
          required: true,
          message: '数值类型不能为空',
        },
      ],
      caption: {
        validator: (rule, value, callback) =>
          this.checkLength(
            rule,
            value,
            callback,
            '',
            '不得超过32汉字或64字符长度',
            64,
          ),
        trigger: 'change',
      },
      remark: {
        validator: (rule, value, callback) =>
          this.checkLength(rule, value, callback, '', '不得超过200字符', 200),
        trigger: 'change',
      },
    };
    switch (this.form.type) {
      case DATATYPE.int:
      case DATATYPE.float:
        result = {
          ...base,
          numberRange: [
            {
              required: true,
              validator: this.checkRange,
              trigger: ['change', 'input'],
            },
          ],
          default: [
            {
              required: true,
              message: '默认数值不能为空',
            },
          ],
          readonly: [
            {
              required: true,
              message: '读写类型不能为空',
            },
          ],
          step: [
            {
              required: true,
              message: '步长不能为空',
            },
          ],
        };
        break;
      case DATATYPE.enum:
        result = {
          ...base,
          numberRange: [
            {
              required: true,
              validator: this.checkEnumRange,
              trigger: ['change', 'input'],
            },
          ],
          default: [
            {
              required: true,
              message: '默认数值不能为空',
            },
          ],
          readonly: [
            {
              required: true,
              message: '读写类型不能为空',
            },
          ],
        };
        break;
      case DATATYPE.boolean:
        result = {
          ...base,
          numberRange: [
            {
              required: true,
              validator: this.checkRange,
              trigger: ['change', 'input'],
            },
          ],
          default: [
            {
              required: true,
              message: '默认数值不能为空',
            },
          ],
          readonly: [
            {
              required: true,
              message: '读写类型不能为空',
            },
          ],
        };
        break;
      case DATATYPE.text:
        result = {
          ...base,
          numberRange: [
            {
              required: true,
              message: '请输入数值范围',
            },
          ],
          default: [
            {
              required: true,
              message: '默认数值不能为空',
            },
          ],
          readonly: [
            {
              required: true,
              message: '读写类型不能为空',
            },
          ],
        };
        break;
      default:
        result = {
          ...base,
        };
    }
    return result;
  }
  /**
   * @name 校验长度和空值
   * @param rule {object} 校验规则
   * @param value {string} 值
   * @param callback {function} 函数
   * @param nullTxt {string} 空值提示文本
   * @param lengthTxt {string} 长度值限制提示文本
   * @param len {number} 限制长度
   */
  checkLength(rule, value, callback, nullTxt, lengthTxt, len) {
    if (value) {
      if (!limitTxtLength(value, len)) {
        callback(lengthTxt);
      } else {
        callback();
      }
    } else {
      if (nullTxt) {
        callback(nullTxt);
      } else {
        callback();
      }
    }
  }
  checkEnumRange(rule, value, callback) {
    if (value.length > 0) {
      let count = 0;
      const size = value.length * 2;
      value.forEach(item => {
        item.number.forEach(v => {
          if (v) {
            count++;
          }
        });
      });
      if (count === size) {
        callback();
      } else {
        callback('请输入数值范围');
      }
    } else {
      callback('请输入数值范围');
    }
  }
  checkRange(rule, value, callback) {
    if (value.length > 0) {
      let count = 0;
      value.forEach(item => {
        if (item !== undefined) {
          count++;
        }
      });
      if (count === 2) {
        callback();
      } else {
        callback('请输入数值范围');
      }
    } else {
      callback('请输入数值范围');
    }
  }
  checkLanguages(rule, value, callback) {
    if (value.length > 0) {
      let count = 0;
      const size = value.length * 2;
      value.forEach(item => {
        item.language.forEach(val => {
          if (val) {
            count++;
          }
        });
      });
      if (count === size) {
        callback();
      } else {
        callback(new Error('请选择语言'));
      }
    } else {
      callback(new Error('请选择语言'));
    }
  }

  types = types;
  unitList = [
    {
      key: '无',
      value: '无',
    },
    {
      key: '℃',
      value: '℃',
    },
    {
      key: '℉',
      value: '℉',
    },
    {
      key: 'min',
      value: 'min',
    },
    {
      key: 'sec',
      value: 'sec',
    },
    {
      key: 'KW',
      value: 'KW',
    },
    {
      key: '%',
      value: '%',
    },
  ];

  /** @name 数据类型 */
  formatSpecs() {
    switch (this.form.type) {
      case DATATYPE.int:
      case DATATYPE.float:
        return {
          defaultValue: this.form.default,
          max: this.form.numberRange[1],
          min: this.form.numberRange[0],
          readWriteMethod: this.form.readonlyType,
          readWriteType: this.form.readonly,
          step: this.form.step,
          unit: this.form.unit,
          autoSupllyConfig: this.form.autoSupllyConfig
            ? this.form.autoSupllyConfig
            : {},
        };
      case DATATYPE.boolean:
        return {
          boolValue: {
            0: this.form.numberRange[0],
            1: this.form.numberRange[1],
          },
          defaultValue: this.form.default,
          readWriteMethod: this.form.readonlyType,
          readWriteType: this.form.readonly,
        };
      case DATATYPE.enum:
        return {
          defaultValue: this.form.default,
          readWriteType: this.form.readonly,
          unit: this.form.unit,
          enumValue: formatList(this.form.numberRange, 'number'),
          multiLanguage: languageFormat(this.form.numberRange, 'number'),
        };
      case DATATYPE.text:
        return {
          defaultValue: this.form.default,
          readWriteType: this.form.readonly,
          textLength: this.form.numberRange,
        };
      default:
        return {};
    }
  }
  async getValue() {
    const flag = await this.$refs.form.validate();
    if (flag) {
      const result = {
        parameterName: this.form.name,
        identifier: this.form.mark,
        promptDescription: this.form.caption,
        remark: this.form.remark,
        multiLanguage: formatList(this.form.languages, 'language'),
        dataType: {
          dataType: this.form.type,
          specs: this.formatSpecs(),
        },
      };
      return result;
    } else {
      return false;
    }
  }

  get maxValue() {
    return this.form.numberRange.length && this.form.numberRange.length === 2
      ? this.form.numberRange[1]
        ? this.form.numberRange[1] - this.form.numberRange[0]
        : 0
      : 0;
  }

  async settingInt() {
    let detail = {};
    if (this.form.autoSupllyConfig && this.form.autoSupllyConfig.value) {
      detail = this.form.autoSupllyConfig;
    }
    const result = await createFormModal(
      () => <SettingForm detail={detail} />,
      {
        width: 400,
        title: '自动填充设置',
      },
    );
    if (result) {
      this.$set(this.form, 'autoSupllyConfig', result);
    }
  }
  renderInt() {
    const step = this.form.type
      ? this.form.type === DATATYPE.int
        ? 1
        : 0.01
      : 1;
    return (
      <div>
        <a-form-model-item label={'数值范围'} prop={'numberRange'}>
          <a-number-range
            flex
            cmp={{
              left: {
                type: 'number',
                placeholder: '最小值',
                step,
              },
              right: {
                type: 'number',
                placeholder: '最大值',
                step,
              },
              middle: {
                txt: '至',
              },
            }}
            v-model={this.form.numberRange}
          />
        </a-form-model-item>
        <a-form-model-item label={'步长'} prop={'step'}>
          <a-input-number
            style="width:100%"
            placeholder={'请输入步长'}
            v-model={this.form.step}
          />
        </a-form-model-item>
        <a-form-model-item label={'默认数值'} prop={'default'}>
          <div class={this.$style.labelFlex}>
            <a-input-number
              class={this.$style.leftLabel}
              placeholder={'请输入默认数值'}
              v-model={this.form.default}
            />
            {this.form.type === DATATYPE.int ? (
              <a-icon
                class={this.$style.rightLabel}
                type="setting"
                onClick={this.settingInt}
              />
            ) : null}
          </div>
        </a-form-model-item>
        <a-form-model-item label={'参数单位'} prop={'unit'}>
          <a-select placeholder={'请选择参数单位'} v-model={this.form.unit}>
            {this.unitList.map(item => (
              <a-select-option value={item.key} key={item.key}>
                {item.value}
              </a-select-option>
            ))}
          </a-select>
        </a-form-model-item>
        <a-form-model-item label={'读写类型'} prop={'readonly'}>
          <a-radio-group v-model={this.form.readonly}>
            <a-radio value={'RW'}> 读写 </a-radio>
            <a-radio value={'R'}> 只读 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label={'读写方式'} prop={'readonlyType'}>
          <a-radio-group v-model={this.form.readonlyType}>
            <a-radio value={'DOWN_PULL'}> 下拉选择 </a-radio>
            <a-radio value={'MANUAL_INPUT'}> 手动输入 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </div>
    );
  }
  renderBoolean() {
    return (
      <div>
        <a-form-model-item label={'数值范围'} prop={'numberRange'}>
          <a-boolean-number-range
            placeholder={'请输入数值范围'}
            v-model={this.form.numberRange}
          />
        </a-form-model-item>
        <a-form-model-item label={'默认数值'} prop={'default'}>
          <a-select placeholder={'请默认数值'} v-model={this.form.default}>
            <a-select-option value={0}>0</a-select-option>
            <a-select-option value={1}>1</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label={'读写类型'} prop={'readonly'}>
          <a-radio-group v-model={this.form.readonly}>
            <a-radio value={'RW'}> 读写 </a-radio>
            <a-radio value={'R'}> 只读 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label={'读写方式'} prop={'readonlyType'}>
          <a-radio-group v-model={this.form.readonlyType}>
            <a-radio value={'SWITCH'}> 开关 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </div>
    );
  }
  renderEnum() {
    return (
      <div>
        <a-form-model-item label={'数值范围'} prop={'numberRange'}>
          <a-enum-number-range v-model={this.form.numberRange} />
        </a-form-model-item>
        <a-form-model-item label={'默认数值'} prop={'default'}>
          <a-input placeholder={'请输入默认数值'} v-model={this.form.default} />
        </a-form-model-item>
        <a-form-model-item label={'参数单位'} prop={'unit'}>
          <a-select placeholder={'请选择参数单位'} v-model={this.form.unit}>
            {this.unitList.map(item => (
              <a-select-option value={item.key} key={item.key}>
                {item.value}
              </a-select-option>
            ))}
          </a-select>
        </a-form-model-item>
        <a-form-model-item label={'读写类型'} prop={'readonly'}>
          <a-radio-group v-model={this.form.readonly}>
            <a-radio value={'RW'}> 读写 </a-radio>
            <a-radio value={'R'}> 只读 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </div>
    );
  }
  renderText() {
    return (
      <div>
        <a-form-model-item label={'数值范围'} prop={'numberRange'}>
          <a-input
            placeholder={'请输入数值范围'}
            v-model={this.form.numberRange}
          />
        </a-form-model-item>
        <a-form-model-item label={'默认数值'} prop={'default'}>
          <a-input placeholder={'请输入默认数值'} v-model={this.form.default} />
        </a-form-model-item>
        <a-form-model-item label={'读写类型'} prop={'readonly'}>
          <a-radio-group v-model={this.form.readonly}>
            <a-radio value={'RW'}> 读写 </a-radio>
            <a-radio value={'R'}> 只读 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </div>
    );
  }

  get renderScheme() {
    switch (this.form.type) {
      case DATATYPE.int:
      case DATATYPE.float:
        return this.renderInt();
      case DATATYPE.enum:
        return this.renderEnum();
      case DATATYPE.text:
        return this.renderText();
      case DATATYPE.boolean:
        return this.renderBoolean();
      default:
        return '';
    }
  }

  render() {
    return (
      <a-form-model
        layout={'vertical'}
        rules={this.rules}
        props={{ model: this.form }}
        ref={'form'}
      >
        <a-form-model-item label={'参数名称'} prop={'name'}>
          <a-input placeholder={'请输入参数名称'} v-model={this.form.name} />
        </a-form-model-item>
        <a-form-model-item label={'提示说明'} prop={'caption'}>
          <a-input placeholder="请输入提示说明" v-model={this.form.caption} />
        </a-form-model-item>
        <a-form-model-item label={'参数标识'} prop={'mark'}>
          <a-input placeholder={'请输入参数标识'} v-model={this.form.mark} />
        </a-form-model-item>
        <a-form-model-item label={'数值类型'} prop={'type'}>
          <a-select
            onChange={type => this.onChangeType(type)}
            placeholder={'请选择数值类型'}
            v-model={this.form.type}
          >
            {this.types.map(item => (
              <a-select-option value={item.key} key={item.key}>
                {item.value}
              </a-select-option>
            ))}
          </a-select>
        </a-form-model-item>
        {this.renderScheme}
        <a-form-model-item label={'备注说明'} prop={'remark'}>
          <a-textarea
            row={6}
            placeholder={'请输入备注'}
            v-model={this.form.remark}
          />
        </a-form-model-item>
        <a-form-model-item label={'多语言'} prop={'languages'}>
          <a-language v-model={this.form.languages} />
        </a-form-model-item>
      </a-form-model>
    );
  }
}
</script>

<style lang="less" module>
.labelFlex {
  display: flex;
  align-items: center;
}
.leftLabel {
  flex: 1;
}
.rightLabel {
  margin-left: 10px;
  font-size: 20px;
}
</style>
