<template>
  <div :class="$style.formWarp">
    <a-form-model ref="form" :model="form" layout="vertical" :rules="rules">
      <a-row :gutter="20">
        <a-col :span="10">
          <a-form-model-item label="参数图标" prop="paramsIcon">
            <ImagesUpload
              :maxCount="1"
              v-model="form.paramsIcon"
              @change="imgUploadChange"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="14">
          <a-form-model-item label="参数名称" prop="paramsName">
            <a-input
              v-model="form.paramsName"
              style="width: 240px"
              :maxLength="8"
              @change="editFlag(true)"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :span="10">
          <a-form-model-item label="参数单位" prop="paramsUnit">
            <a-input
              v-model="form.paramsUnit"
              style="width: 240px"
              @change="editFlag(true)"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="14">
          <a-form-model-item prop="min" ref="min">
            <span slot="label">
              <span>数值区间</span>
              <a-tooltip title="仅允许输入0-9999的整数">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </span>
            <div style="display: flex;align-items: center; width: 240px">
              <a-input-number
                v-model="form.min"
                :min="0"
                :max="9998"
                @blur="
                  () => {
                    $refs.min.onFieldBlur();
                  }
                "
                @change="
                  () => {
                    editFlag(true);
                    $refs.min.onFieldChange();
                  }
                "
              />
              <span style="padding: 0 5px">至</span>
              <a-input-number
                v-model="form.max"
                :min="1"
                :max="9999"
                @blur="
                  () => {
                    $refs.min.onFieldBlur();
                  }
                "
                @change="
                  () => {
                    editFlag(true);
                    $refs.min.onFieldChange();
                  }
                "
              />
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :span="10">
          <a-form-model-item label="展示格式" prop="showFormat">
            <a-radio-group
              v-model="form.showFormat"
              :options="showFormat"
              @change="editFlag(true)"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="14">
          <a-form-model-item label="组件样式" prop="componentStyle">
            <a-radio-group
              v-model="form.componentStyle"
              :options="componentFormat"
              @change="editFlag(true)"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :span="10">
          <a-form-model-item label="步长" prop="step">
            <a-input-number
              v-model="form.step"
              style="width: 240px"
              :min="1"
              :max="form.max"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div :class="$style.button">
      <a-button type="primary" @click="saveAll">保存</a-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ImagesUpload from '../../images-upload';
import { deepClone } from '@triascloud/utils';

@Component({
  components: {
    ImagesUpload,
  },
})
export default class Parameter extends Vue {
  @Prop({ type: Object, default: () => {} }) parameterData;

  form = {
    paramsIcon: [],
    paramsName: '',
    min: 0,
    max: 0,
    paramsUnit: '',
    showFormat: 'INT',
    componentStyle: 'DOWN_PULL',
    step: 1,
  };
  rules = {
    paramsIcon: [
      {
        required: true,
        message: '请上传参数图标',
      },
    ],
    paramsName: [
      {
        required: true,
        message: '参数名称不允许为空',
      },
    ],
    paramsUnit: [
      {
        required: true,
        message: '参数单位不允许为空',
      },
    ],
    min: [
      {
        required: true,
        validator: this.checkNumRange,
        trigger: ['change', 'blur'],
      },
    ],
    showFormat: [
      {
        required: true,
        message: '请选择展示格式',
      },
    ],
    componentStyle: [
      {
        required: true,
        message: '请选择组件样式',
      },
    ],
  };
  showFormat = [
    {
      value: 'INT',
      label: '整数',
    },
    {
      value: 'TIME',
      label: '时长-分:秒',
    },
    {
      value: 'TEMPERATURE',
      label: '温度',
    },
  ];
  componentFormat = [
    {
      value: 'DOWN_PULL',
      label: '下拉选择',
    },
    {
      value: 'PROGRESS_BAR',
      label: '进度条',
    },
  ];

  @Watch('parameterData', { immediate: true, deep: true })
  changeData(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.form.paramsName = newVal.paramsName;
      this.form = deepClone(newVal);
    }
  }
  checkNumRange(rule, value, callback) {
    const minFlag = this.isEmpty(value);
    const maxFlag = this.isEmpty(this.form.max);
    if (!minFlag || !maxFlag) {
      callback(new Error('数值区间不允许为空'));
    } else if (value >= this.form.max) {
      callback(new Error('请保持左侧数值小于右侧数值'));
    } else {
      callback();
    }
  }
  isEmpty(v) {
    return v !== '' && v !== undefined && v !== null;
  }
  // eslint-disable-next-line no-unused-vars
  imgUploadChange(file) {
    this.editFlag(true);
  }
  editFlag(v) {
    this.$emit('editFlag', v);
  }
  resetForm() {
    this.editFlag(false);
    this.form = deepClone(this.parameterData);
  }
  saveAll() {
    this.$emit('paramId', this.parameterData.id);
    this.$refs.form.validate(valid => {
      if (valid) {
        this.editFlag(false);
        this.$emit('saveAll', this.form);
      } else {
        this.editFlag(true);
        return false;
      }
    });
  }
}
</script>

<style lang="less" module>
.img {
  width: 120px;
  height: 120px;
}
.formWarp {
  height: 391px;
  position: relative;
  .button {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
</style>
