<template>
  <a-form-model
    class="addCameraBoxwrapper"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-form-model-item label="产品标识" prop="productSign">
      <a-input
        placeholder="请输入产品标识"
        :disabled="!canEdit"
        v-model="form.productSign"
        :maxLength="64"
      />
    </a-form-model-item>
    <a-form-model-item label="产品分类" prop="productType">
      <a-select
        v-model="form.productType"
        placeholder="请选择产品分类"
        @change="handleProductType"
        :disabled="editData.state !== undefined && editData.state !== 'DEV'"
      >
        <a-select-option
          :value="item.id"
          v-for="item in productTypes"
          :key="item.id"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="软件版本" prop="version">
      <a-radio-group name="version" v-model="form.version" disabled>
        <a-radio v-for="item in productVersion" :value="item.id" :key="item.id">
          {{ item.label }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item label="产品头图" prop="images">
      <images-upload
        v-model="form.images"
        :maxCount="5"
        :isDisable="editData.state !== undefined && editData.state !== 'DEV'"
        :pkId="pkId"
      />
    </a-form-model-item>
    <a-form-model-item label="关联文件" prop="settingFiles">
      <recipes-upload
        v-model="form.settingFiles"
        :maxCount="12"
        :pkId="pkId"
        :isDisable="editData.state !== undefined && editData.state !== 'DEV'"
      ></recipes-upload>
    </a-form-model-item>
    <a-form-model-item label="操作指南" prop="operateGuide">
      <div class="hFlex">
        <a-input
          v-model="form.operateGuide"
          placeholder="请输入操作指南"
          :maxLength="200"
        />
      </div>
    </a-form-model-item>
    <a-form-model-item label="官网介绍" prop="officialInfo">
      <a-input
        v-model="form.officialInfo"
        placeholder="请输入官网介绍"
        :maxLength="200"
      />
    </a-form-model-item>
    <a-form-model-item label="备注说明" prop="remark">
      <a-input
        :maxLength="32"
        placeholder="请输入备注说明"
        v-model="form.remark"
      />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ImagesUpload from './images-upload.vue';
import RecipesUpload from './recipes-upload.vue';
import 'vue-advanced-cropper/dist/style.css';
import {
  addProduct,
  getProductInfo,
  updateProduct,
} from '@/services/oven/product';
import { UploadService } from '@triascloud/services';

@Component({
  components: {
    ImagesUpload,
    RecipesUpload,
  },
})
export default class AddEditProduct extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) operationFlag;
  @Prop({ type: String, default: '' }) pkId;
  @Watch('editData', { immediate: true, deep: true })
  changeData(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.canEdit = (newVal.state && newVal.state === 'DEV') || true;
    }
  }

  canEdit = true;
  form = {
    productSign: '',
    productType: undefined,
    images: [
      // {
      //   uid: '-1',
      //   name: 'image.png',
      //   status: 'done',
      //   url:
      //     'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // },
    ],
    settingFiles: [
      {
        fileName: 'menue.txt',
        menuName: '食谱设置',
        canEdit: false,
        relatedFilesEnum: 'RECIPE_SETTINGS',
      },
      {
        fileName: 'favorites.txt',
        menuName: '收藏管理',
        canEdit: false,
        relatedFilesEnum: 'COLLECT_MANAGE',
      },
      {
        fileName: 'status.txt',
        menuName: '设备状态',
        canEdit: false,
        relatedFilesEnum: 'DEVICE_STATUS',
      },
      {
        fileName: 'haccp_this_month.txt',
        menuName: '本月日志',
        canEdit: false,
        relatedFilesEnum: 'THIS_MONTH_LOGGER',
      },
      {
        fileName: 'haccp_last_month.txt',
        menuName: '上月日志',
        canEdit: false,
        relatedFilesEnum: 'LAST_MONTH_LOGGER',
      },
      {
        fileName: 'warnings.txt',
        menuName: '设备预警',
        canEdit: false,
        relatedFilesEnum: 'DEVICE_WARNING',
      },
    ],
    operateGuide: '',
    officialInfo: '',
    remark: '',
  };
  imageUrl = '';
  checkFiles(rule, value, callback) {
    let hasEmptyFile = false;
    let hasWrongName = false;
    value.forEach(item => {
      if (!item.fileName) {
        hasEmptyFile = true;
      } else if (!/[\w]+(.txt)$/g.test(item.fileName)) {
        hasWrongName = true;
      }
    });
    if (hasEmptyFile) {
      return callback(new Error('关联文件不能为空'));
    } else if (hasWrongName) {
      return callback(new Error('文件名需要以.txt结尾'));
    } else {
      return callback();
    }
  }
  rules = {
    productSign: [
      {
        required: true,
        message: '产品标识不能为空',
      },
    ],
    productType: [
      {
        required: true,
        message: '产品分类不能为空',
      },
    ],
    images: [
      {
        required: true,
        message: '产品头图不能为空',
      },
    ],
    settingFiles: [
      {
        required: true,
        // message: '关联文件不能为空',
        validator: this.checkFiles,
        trigger: 'change',
      },
    ],
  };
  productTypes = [];
  productVersion = [];
  mounted() {
    this.getProductInfos();
    this.init();
  }
  async init() {
    if (this.operationFlag === 'edit') {
      const list = this.editData;
      this.form.productSign = list.identification;
      this.form.productType = list.idxSoftwareVersion;
      this.form.version = list.idxSoftwareVersion;
      const basePath = '/oss/iot/oss';
      const customService = new UploadService(basePath);
      const abPath = await customService.getAuth(list.picture);
      this.form.images = abPath.map((v, index) => {
        return {
          uid: index,
          name: `picture${index}`,
          status: 'done',
          url: v,
          ossPath: list.picture[index],
        };
      });
      this.form.remark = list.remark;
      const result = [];
      list.relatedFiles.forEach(v => {
        const fItem = this.form.settingFiles.find(
          item => item.fileName === v.fileName,
        );
        if (!fItem) {
          result.push({
            pkId: v.id !== '' ? v.id : undefined,
            fileName: v.fileName,
            fileUrl: v.fileUrl,
            menuName: v.menuName,
            canEdit: false,
            showDelete: v.relatedFilesEnum === 'CUSTOMIZE' ? true : undefined,
          });
        } else {
          Vue.set(fItem, 'fileUrl', v.fileUrl);
          Vue.set(fItem, 'pkId', v.id);
        }
      });
      this.form.settingFiles.push(...result);
      this.form.operateGuide = list.operationGuide;
      this.form.officialInfo = list.officialWebsite;
    }
  }
  async getProductInfos() {
    try {
      const result = await getProductInfo();
      result.forEach(v => {
        const o1 = {
          id: v.pkId,
          label: v.productType,
        };
        this.productTypes.push(o1);
        const o2 = {
          id: v.pkId,
          label: v.softwareVersion,
        };
        this.productVersion.push(o2);
      });
    } catch {
      return false;
    }
  }

  handleProductType(v) {
    this.form.productType = v;
    this.form.version = v;
  }
  getValue() {
    const params = {
      identification: this.form.productSign,
      idxSoftwareVersion: this.form.productType,
      operationGuide: this.form.operateGuide,
      officialWebsite: this.form.officialInfo,
      picture: this.form.images.map(v => v.ossPath),
      baseRelatedFiles: this.form.settingFiles,
      remark: this.form.remark,
    };
    if (this.operationFlag === 'edit') {
      params['id'] = this.editData.pkId;
    }
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.operationFlag === 'add'
              ? await addProduct(params)
              : await updateProduct(params);
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" scoped>
.addCameraBoxwrapper {
  .settingsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .settingsBtn {
      // border: 1px solid #eee;
      margin-left: 5px;
    }
  }
  .hFlex {
    display: flex;
  }
  .addFileBtn {
    cursor: pointer;
  }
}
</style>
