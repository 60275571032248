<template>
  <div :class="$style.formBox" ref="boxRef">
    <section :class="$style.inputItem">
      0-
      <a-input
        :class="[$style.input]"
        v-model="form.left"
        placeholder="如：关"
        @input="event => handleChange(event, 'left')"
      ></a-input>
    </section>
    <section :class="$style.inputItem">
      1-
      <a-input
        :class="[$style.input]"
        v-model="form.right"
        placeholder="如：开"
        @input="event => handleChange(event, 'right')"
      ></a-input>
    </section>
  </div>
</template>
<script>
import { Component, Model, Vue, Prop, Watch } from 'vue-property-decorator';
@Component()
export default class BooleanNumberRange extends Vue {
  form = {
    left: '',
    right: '',
  };

  @Model('change.value') value;
  @Prop({ type: Array }) value;

  handleChange(event, type) {
    let result = [];
    if (type === 'left') {
      result[0] = event.target.value;
      result[1] = this.form.right || '';
    } else {
      result[0] = this.form.left || '';
      result[1] = event.target.value;
    }
    this.$emit('change.value', result);
    this.$emit('change', result);
    this.$emit('input', result);
  }

  @Watch('value', { deep: true, immediate: true })
  onValueWatch(value) {
    this.form.left = value.length > 0 ? value[0] : '';
    this.form.right = value.length > 1 ? value[1] : '';
  }
}
</script>
<style lang="less" module>
.formBox {
  display: flex;
  width: 100%;

  .inputItem {
    flex: 1;
    display: flex;
  }

  .inputItem + .inputItem {
    margin-left: 20px;
  }

  .input {
    margin-left: 8px;
  }
}
</style>
