export const DATATYPE = {
  int: 'INT16',
  float: 'FLOAT',
  boolean: 'BOOL',
  enum: 'ENUM',
  text: 'TEXT',
};
export const types = [
  {
    key: DATATYPE.int,
    value: 'int16(整数型)',
  },
  {
    key: DATATYPE.float,
    value: 'float(单精度浮点)',
  },
  {
    key: DATATYPE.boolean,
    value: 'bool(布尔型)',
  },
  {
    key: DATATYPE.enum,
    value: 'enum(枚举型)',
  },
  {
    key: DATATYPE.text,
    value: 'text(字符串)',
  },
];
