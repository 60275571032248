<template>
  <div class="recipesDiv" ref="boxRef" :class="{ hasError: hasError }">
    <div v-for="(item, index) in settingFiles" :key="index">
      <div v-if="item.canEdit" class="settingsDiv">
        <span class="labelText">{{ item.menuName }}</span>
        <span class="filesInput" :class="{ noError: item.noError }">
          <a-input v-model="item.fileName" @blur="fileNameChanged()">
            <a-upload
              slot="addonAfter"
              :showUploadList="false"
              name="file"
              :multiple="false"
              :customRequest="customUpload"
              accept="text/plain"
              @change="files => settingFilesChanged(files, item, index)"
            >
              <div>
                <a-icon type="upload" />
              </div>
            </a-upload>
          </a-input>
        </span>
        <span class="settingsBtn" @click="confirmFiles(index)"
          ><a-icon type="check-circle"
        /></span>
        <span
          v-if="item.showDelete"
          class="settingsBtn"
          @click="deleteFiles(index)"
          ><a-icon type="close"
        /></span>
      </div>
      <div v-else class="settingsDiv">
        <span class="labelText">{{ item.menuName }}</span>
        <span class="filesInput noError">
          <a-input v-model="item.fileName" :read-only="true">
            <a-icon
              type="download"
              slot="addonAfter"
              @click="downloadFile(item.fileUrl, item.fileName)"
            /> </a-input
        ></span>
        <span class="settingsBtn" v-if="!isDisable"
          ><a-icon type="edit" @click="item.canEdit = true"
        /></span>
      </div>
    </div>
    <div>
      <span
        @click="addSettingFile"
        class="addFileBtn"
        v-if="!isDisable && settingFiles.length < maxCount"
        ><a-icon type="plus" />自定义菜单关联文件</span
      >
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue, Model, Watch } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';

@Component({})
export default class RecipesUpload extends Vue {
  @Model('change.value') value;
  @Prop({ type: Array }) value;
  @Prop({ type: Boolean, default: false }) isDisable;
  @Prop({ type: Number, default: 3 }) maxCount;
  @Prop({ type: String, default: '' }) pkId;
  settingFiles = [];
  @Watch('value', { deep: true, immediate: true })
  onValue(value) {
    this.settingFiles = value;
  }
  created() {}
  addSettingFile() {
    let i = this.settingFiles.length - 1;
    this.settingFiles.push({
      showDelete: true,
      fileName: '',
      menuName: '自定义' + i,
      canEdit: true,
      noError: false,
      relatedFilesEnum: 'CUSTOMIZE',
    });
  }
  async customUpload() {}
  async downloadFile(ossPath, filename) {
    if (!ossPath) {
      this.$message.warning('未上传关联文件！');
      return;
    }
    const uploadPath = '/oss/iot/oss';
    const customService = new UploadService(uploadPath);
    const abPath = await customService.getAuth(ossPath);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', abPath, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        this.saveAs(filename, xhr.response);
      }
    };
    xhr.send();
  }
  saveAs(filename, blob) {
    const link = document.createElement('a');
    const body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.style.display = 'none';
    body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
    body.removeChild(link);
  }
  async settingFilesChanged(files, item, index) {
    this.settingFiles[index].file = files.file;
    this.$set(this.settingFiles[index], 'fileName', files.file.name);

    const uploadPath = `${this.pkId}/kolb/files`;
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    this.settingFiles[index].fileUrl = await customService.upload(
      files.file.originFileObj,
      uploadPath,
      () => {},
      false,
    );
    // this.settingFiles.splice(index, 1, item);
    // console.log(ossPath);
    // console.log(this.settingFiles);
    // console.log(file);
    // const abPath = await customService.getAuth(ossPath);
    // console.log(abPath);
    // this.download(abPath, 'warnings.txt');
    this.emitChangeEvent();
  }
  fileNameChanged() {
    this.emitChangeEvent();
  }
  emitChangeEvent() {
    this.$emit('change.value', this.settingFiles);
    this.$emit('change', this.settingFiles);
    this.$emit('input', this.settingFiles);
    this.findClass();
  }
  deleteFiles(index) {
    this.settingFiles.splice(index, 1);
    for (let i = 0; i < this.settingFiles.length; i++) {
      if (i > 1) {
        this.settingFiles[i].menuName = '自定义' + (i - 1);
      }
    }
    this.emitChangeEvent();
  }

  confirmFiles(index) {
    if (
      this.settingFiles[index].fileName &&
      /[\w]+(.txt)$/g.test(this.settingFiles[index].fileName)
    ) {
      this.settingFiles[index].canEdit = false;
    }
    // else {
    //   this.$message.warning('文件名需要以.txt结尾!');
    // }
    this.emitChangeEvent();
  }
  validateInput() {
    for (let item of this.settingFiles) {
      if (item.fileName && /[\w]+(.txt)$/g.test(item.fileName)) {
        item.noError = true;
      }
    }
  }
  hasError = false;
  findClass() {
    this.validateInput();
    setTimeout(() => {
      try {
        let parentNode = this.$refs.boxRef.parentNode;
        while (
          !Array.from(parentNode.classList).find(
            v => v === 'ant-form-item-control',
          )
        ) {
          parentNode = parentNode.parentNode;
        }
        const result = Array.from(parentNode.classList).find(
          v => v === 'has-error',
        );
        this.hasError = !!result;
      } catch (error) {
        this.hasError = false;
        return false;
      }
    }, 20);
  }
}
</script>

<style lang="less" scoped>
.hasError {
  .noError {
    ::v-deep input.ant-input {
      border: 1px solid var(--form-border);
    }
    ::v-deep .ant-input-group-addon {
      border: 1px solid var(--form-border);
      color: var(--font-sub);
    }
  }
}
.recipesDiv {
  .settingsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    .settingsBtn {
      // border: 1px solid #eee;
      margin-left: 5px;
    }
  }
  .labelText {
    color: var(--font-info);
    display: block;
    width: 70px;
    margin-left: 15px;
  }
  .filesInput {
    width: 250px;
  }
  .addFileBtn {
    cursor: pointer;
    margin-left: 15px;
    color: var(--primary);
  }
}
</style>
