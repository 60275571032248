var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.formWarp},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.form,"layout":"vertical","rules":_vm.rules}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":10}},[_c('a-form-model-item',{attrs:{"label":"参数图标","prop":"paramsIcon"}},[_c('ImagesUpload',{attrs:{"maxCount":1},on:{"change":_vm.imgUploadChange},model:{value:(_vm.form.paramsIcon),callback:function ($$v) {_vm.$set(_vm.form, "paramsIcon", $$v)},expression:"form.paramsIcon"}})],1)],1),_c('a-col',{attrs:{"span":14}},[_c('a-form-model-item',{attrs:{"label":"参数名称","prop":"paramsName"}},[_c('a-input',{staticStyle:{"width":"240px"},attrs:{"maxLength":8},on:{"change":function($event){return _vm.editFlag(true)}},model:{value:(_vm.form.paramsName),callback:function ($$v) {_vm.$set(_vm.form, "paramsName", $$v)},expression:"form.paramsName"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":10}},[_c('a-form-model-item',{attrs:{"label":"参数单位","prop":"paramsUnit"}},[_c('a-input',{staticStyle:{"width":"240px"},on:{"change":function($event){return _vm.editFlag(true)}},model:{value:(_vm.form.paramsUnit),callback:function ($$v) {_vm.$set(_vm.form, "paramsUnit", $$v)},expression:"form.paramsUnit"}})],1)],1),_c('a-col',{attrs:{"span":14}},[_c('a-form-model-item',{ref:"min",attrs:{"prop":"min"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('span',[_vm._v("数值区间")]),_c('a-tooltip',{attrs:{"title":"仅允许输入0-9999的整数"}},[_c('a-icon',{attrs:{"type":"question-circle-o"}})],1)],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","width":"240px"}},[_c('a-input-number',{attrs:{"min":0,"max":9998},on:{"blur":function () {
                  _vm.$refs.min.onFieldBlur();
                },"change":function () {
                  _vm.editFlag(true);
                  _vm.$refs.min.onFieldChange();
                }},model:{value:(_vm.form.min),callback:function ($$v) {_vm.$set(_vm.form, "min", $$v)},expression:"form.min"}}),_c('span',{staticStyle:{"padding":"0 5px"}},[_vm._v("至")]),_c('a-input-number',{attrs:{"min":1,"max":9999},on:{"blur":function () {
                  _vm.$refs.min.onFieldBlur();
                },"change":function () {
                  _vm.editFlag(true);
                  _vm.$refs.min.onFieldChange();
                }},model:{value:(_vm.form.max),callback:function ($$v) {_vm.$set(_vm.form, "max", $$v)},expression:"form.max"}})],1)])],1)],1),_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":10}},[_c('a-form-model-item',{attrs:{"label":"展示格式","prop":"showFormat"}},[_c('a-radio-group',{attrs:{"options":_vm.showFormat},on:{"change":function($event){return _vm.editFlag(true)}},model:{value:(_vm.form.showFormat),callback:function ($$v) {_vm.$set(_vm.form, "showFormat", $$v)},expression:"form.showFormat"}})],1)],1),_c('a-col',{attrs:{"span":14}},[_c('a-form-model-item',{attrs:{"label":"组件样式","prop":"componentStyle"}},[_c('a-radio-group',{attrs:{"options":_vm.componentFormat},on:{"change":function($event){return _vm.editFlag(true)}},model:{value:(_vm.form.componentStyle),callback:function ($$v) {_vm.$set(_vm.form, "componentStyle", $$v)},expression:"form.componentStyle"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":10}},[_c('a-form-model-item',{attrs:{"label":"步长","prop":"step"}},[_c('a-input-number',{staticStyle:{"width":"240px"},attrs:{"min":1,"max":_vm.form.max},model:{value:(_vm.form.step),callback:function ($$v) {_vm.$set(_vm.form, "step", $$v)},expression:"form.step"}})],1)],1)],1)],1),_c('div',{class:_vm.$style.button},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.saveAll}},[_vm._v("保存")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }