<template>
  <layout-content>
    <template v-slot:header-left>
      <span :class="$style.title">产品管理</span>
    </template>
    <template v-if="develop" v-slot:header-right>
      <div :class="$style.headerRight">
        <a-button :class="$style.btn" @click="goBack">返回</a-button>
      </div>
    </template>
    <div v-if="!develop" :class="$style.content" slot="content">
      <div :class="$style.search">
        <a-form-model :model="searchForm" layout="inline">
          <a-form-model-item>
            <a-select
              style="width: 180px"
              v-model="searchForm.searchProductClassfy"
              allow-clear
              placeholder="全部产品分类"
              @change="handleProductSortChange"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option
                v-for="item in productClassfy"
                :key="item.id"
                :value="item.id"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-select
              style="width: 180px"
              v-model="searchForm.productStatus"
              placeholder="全部产品状态"
              allow-clear
              @change="handleDeviceStatusChange"
            >
              <a-select-option
                v-for="(item, idx) in statusArr"
                :key="idx"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div>
          <a-button type="primary" @click="openProductForm({}, 'add')">
            新增产品
          </a-button>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :row-key="record => record.id"
        :loading="loading"
        @change="handlePagination"
      >
        <span slot="currentLayer">
          <span slot="currentLayer">设备层数</span>
          <a-tooltip placement="top">
            <template slot="title">
              <span>
                统计所有设备中需要使用当前产品的层数，注意：使用层数不等于最终设备台数！
              </span>
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </span>
        <template slot="identification" slot-scope="text, record">
          <div :class="$style.headIcon">
            <x-oss-image
              basePath="/oss/iot/oss"
              :ossPath="record.picture[0]"
              @click="v => imageClick(v, record.picture)"
              style="width: 80px;height: 80px;padding: 5px;border-radius: 8px"
            />
            <div :class="$style.sign">
              <div :class="$style.productTitle" :title="text">
                {{ text }}
              </div>
              <div>
                <span>产品分类：</span>
                <span>{{ record.groupName }}</span>
              </div>
              <div>
                <span>软件版本：</span>
                <span>{{ record.softwareVersion }}</span>
              </div>
            </div>
          </div>
        </template>
        <template slot="createBy" slot-scope="text, record">
          <div style="display: flex;align-items: center;">
            <Avatar :name="text" :img="record.createByAvatar" />
            <a-tooltip placement="top">
              <template slot="title">
                <span>{{ record.createByPhone }}</span>
                <span
                  @click.stop="handleCopy(record)"
                  style="color: var(--primary);margin-left: 10px; cursor: pointer"
                >
                  点击复制
                </span>
              </template>
              <x-icon
                :class="$style.iconStyle"
                type="tc-icon-dianhua1"
              ></x-icon>
            </a-tooltip>
          </div>
        </template>
        <template slot="state" slot-scope="text">
          <span v-if="text === 'DEV'">
            待开发
          </span>
          <span v-if="text === 'UP_SHELF'">
            已上架
          </span>
          <span v-if="text === 'WAIT_UP_SHELF'">
            待上架
          </span>
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{ stateTextObj[text] }}</span>
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </template>
        <template slot="createDate" slot-scope="record">
          <div>{{ formatTime(record) }}</div>
        </template>
        <template slot="action" slot-scope="record">
          <span :class="$style.buttonGroups">
            <a
              :class="$style.button"
              @click.stop="openProductForm(record, 'edit')"
              >编辑</a
            >
            <a :class="$style.button" @click.stop="handleDevelop(record)">
              开发
            </a>
            <a
              v-if="record.state === 'WAIT_UP_SHELF'"
              :class="$style.button"
              @click.stop="upAndDownJia(record, true)"
            >
              上架
            </a>
            <a
              v-if="record.state === 'UP_SHELF'"
              :class="$style.button"
              @click.stop="upAndDownJia(record, false)"
            >
              下架
            </a>
            <a
              v-if="record.state === 'DEV'"
              :class="$style.button"
              @click.stop="copy(record)"
              >复制</a
            >
            <a
              v-if="record.state !== 'UP_SHELF'"
              :class="$style.button"
              style="color: red"
              @click.stop="delItem(record)"
            >
              删除
            </a>
          </span>
        </template>
      </a-table>
    </div>
    <div v-if="develop" :class="$style.developMain" slot="content">
      <customized :productId="productId" />
    </div>
  </layout-content>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import { Button } from 'ant-design-vue';
import { createFormModal, createModal } from '@triascloud/x-components';
import { ovenModule, crossStorageModule } from '@/enum/store';
import Customized from './components/customized';
import AddEditProduct from './components/add-edit-product.vue';
import DevelopLoginForm from './components/customized/develop-login-form';
import Tip from '@/components/tip';
import Avatar from '@/components/avatar';
import { clipboardWrite } from '@triascloud/utils';
import {
  getProductList,
  deleteProduct,
  copyProduct,
  upDownProduct,
  getProductInfo,
} from '@/services/oven/product';
import { UploadService } from '@triascloud/services';

@Component({
  components: {
    LayoutContent,
    Customized,
    AddEditProduct,
    Tip,
    Avatar,
  },
})
export default class Product extends Vue {
  @ovenModule.State develop;
  @ovenModule.Mutation SET_DEVELOP;
  @crossStorageModule.State('tenant') tenant;
  statusArr = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '待开发',
      value: 'DEV',
    },
    {
      label: '待上架',
      value: 'WAIT_UP_SHELF',
    },
    {
      label: '已上架',
      value: 'UP_SHELF',
    },
  ];

  stateTextObj = {
    DEV: '需要联系开发人员完成参数配置后方可上架',
    UP_SHELF: '客户可正常添加并使用此设备',
    WAIT_UP_SHELF: '已完成参数配置和调试，点击“上架“后可正常使用',
  };

  searchForm = {
    productStatus: undefined,
    searchProductClassfy: undefined,
  };
  productClassfy = [];
  async getProductInfos() {
    try {
      const result = await getProductInfo();
      result.forEach(v => {
        const o1 = {
          id: v.pkId,
          label: v.productType,
        };
        this.productClassfy.push(o1);
      });
    } catch {
      return false;
    }
  }
  handleProductSortChange(v) {
    this.searchForm.searchProductClassfy = v;
    this.getTableList();
  }
  handleDeviceStatusChange(v) {
    this.searchForm.productStatus = v;
    this.getTableList();
  }
  columns = [
    {
      align: 'left',
      title: '产品信息',
      dataIndex: 'identification',
      width: 400,
      scopedSlots: { customRender: 'identification' },
    },
    {
      align: 'left',
      title: '产品状态',
      dataIndex: 'state',
      scopedSlots: { customRender: 'state' },
    },
    {
      align: 'left',
      title: '创建人',
      dataIndex: 'createBy',
      scopedSlots: { customRender: 'createBy' },
    },
    {
      slots: { title: 'currentLayer' },
      align: 'left',
      dataIndex: 'layers',
      customRender: v => (v === -1 ? 0 + '层' : `${v}层`),
    },
    {
      title: '创建时间',
      align: 'left',
      dataIndex: 'createDate',
      scopedSlots: { customRender: 'createDate' },
    },
    {
      title: '备注说明',
      ellipsis: true,
      align: 'left',
      dataIndex: 'remark',
    },
    {
      width: 240,
      title: '操作',
      key: 'operation',
      scopedSlots: { customRender: 'action' },
    },
  ];
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  mounted() {
    this.SET_DEVELOP(false);
    this.getTableList();
    this.getProductInfos();
  }
  a = '';
  async imageClick(v, pictures) {
    this.a = v;
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(pictures);
    this.$preview(abPath, 'image');
  }
  tableData = [];
  async getTableList() {
    const params = {
      pageNum: this.pagination.current,
      pageSize: this.pagination.pageSize,
      state:
        this.searchForm.productStatus !== ''
          ? this.searchForm.productStatus
          : undefined,
      groupId:
        this.searchForm.searchProductClassfy !== undefined
          ? this.searchForm.searchProductClassfy
          : undefined,
    };
    try {
      const { records, current, total } = await getProductList(params);
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.total = total;
    } catch {
      return false;
    }
  }
  loading = false;
  productId = '';
  developModel = undefined;
  handleDevelop(row) {
    this.devCount = 1;
    this.productId = row.pkId;
    const text = '请在完善“编辑”信息后联系开发人员进行开发调试工作！';
    try {
      this.developModel = createModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <span>{text}</span>
            </template>
          </Tip>
        ),
        {
          width: '442px',
          title: <span onClick={() => this.handleDevClick()}>操作提示</span>,
          footer: h => [
            h(
              Button,
              {
                on: {
                  click: () => this.resetDevCount(),
                },
              },
              '取消',
            ),
            h(
              Button,
              {
                props: { type: 'primary' },
                on: {
                  click: () => this.resetDevCount(),
                },
              },
              '好的，我已知晓',
            ),
          ],
        },
      );
    } catch {
      return false;
    }
  }
  devCount = 1;
  resetDevCount() {
    this.developModel?.handleClose();
    this.devCount = 1;
  }
  devPwdModel = undefined;
  handleDevClick() {
    this.devCount++;
    if (this.devCount >= 5) {
      this.developModel.handleClose();
      this.devPwdModel = createModal(
        () => <DevelopLoginForm onResult={pwd => this.devPwdResult(pwd)} />,
        {
          width: '442px',
          title: '操作提示',
        },
      );
    }
  }
  devPwdResult(pwd) {
    if (pwd) {
      this.devPwdModel.handleClose();
      this.SET_DEVELOP(true);
    }
  }
  formatTime(str) {
    if (str) {
      return this.$moment(str).format('YYYY-MM-DD HH:mm:ss');
    } else {
      return '';
    }
  }
  edit() {}
  async copy(row) {
    const id = row.pkId;
    try {
      await copyProduct(id);
      this.$message.success('复制成功');
      await this.getTableList();
    } catch {
      return false;
    }
  }
  async delItem(row) {
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <div>
                <span>确认要删除此款产品？</span>
                <span style="color: #F72F2F;display:block;">
                  删除后所有产品信息将丢失，且无法恢复！
                </span>
              </div>
            </template>
          </Tip>
        ),
        {
          width: '442px',
          title: '操作提示',
          okText: '确定',
          onOk: () => {
            this.deleteProduct(row.pkId);
          },
        },
      );
    } catch {
      return false;
    }
  }
  async deleteProduct(id) {
    try {
      await deleteProduct(id);
      this.$message.success('删除成功');
      await this.getTableList();
    } catch {
      return false;
    }
  }
  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getTableList();
  }
  async openProductForm(row, flag) {
    let title = flag === 'edit' ? '编辑产品' : '添加产品';
    const pkId = this.tenant.pkId;
    try {
      const result = await createFormModal(
        () => (
          <AddEditProduct editData={row} operationFlag={flag} pkId={pkId} />
        ),
        {
          width: '500px',
          title,
        },
      );
      if (result) {
        await this.getTableList();
      }
    } catch (error) {
      if (error.message !== 'user cancel') this.$message.error(error.message);
    }
  }
  async handleCopy(item) {
    const str = `姓名：${item.createBy}\n手机号码：${item.createByPhone}`;
    await clipboardWrite(str);
    this.$message.success('复制成功');
  }
  goBack() {
    this.SET_DEVELOP(false);
    this.getTableList();
  }
  beforeRouteLeave(to, from, next) {
    this.SET_DEVELOP(false);
    next();
  }
  async upAndDownJia(row, flag) {
    const state = flag ? 'UP_SHELF' : 'WAIT_UP_SHELF';
    const message = flag ? '上架成功' : '下架成功';
    const params = {
      id: row.pkId,
      state,
    };
    try {
      await upDownProduct(params);
      this.$message.success(message);
      await this.getTableList();
    } catch {
      return false;
    }
  }
}
</script>
<style lang="less" module>
.title {
  font-size: 16px;
}
.content {
  padding: 20px 28px;
}
.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.developMain {
  padding: 20px;
}
.headerRight {
  margin-right: 10px;
  .btn {
    margin: 0 0 0 10px;
  }
}
.headIcon {
  display: flex;
  align-items: center;
  width: 250px;
  .sign {
    line-height: 25px;
    margin-left: 10px;
    .productTitle {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 300px;
    }
  }
}
.buttonGroups {
  display: flex;
  justify-content: flex-start;
  .red {
    color: red;
  }
  .button {
    width: 50px;
    position: relative;
  }
}
.iconStyle {
  cursor: pointer;
  color: var(--primary);
}
</style>
