<template>
  <div>
    <div :class="$style.labelBox">
      <span :class="$style.label">参数值</span>
      <span :class="$style.label">参数描述</span>
    </div>
    <section
      :class="$style.formBox"
      v-for="(item, index) in list"
      :key="'enum' + index"
    >
      <number-range
        hasCheck
        :ref="'ref' + index"
        :class="$style.item"
        v-model="item.number"
        @change="event => handleChange(event, index)"
      />
      <span :class="$style.span" v-if="index === 0"></span>
      <x-icon
        type="tc-icon-data-source"
        :class="$style.icon"
        @click.stop="handleAddLanguage(item)"
      />
      <x-icon
        type="tc-icon-delete"
        :class="$style.icon"
        v-if="index !== 0"
        @click.stop="handleDeleteItem(index)"
      />
    </section>
    <div :class="$style.add" v-if="list.length <= 19">
      <span @click="handleAddItem">
        <x-icon type="tc-icon-icon-test1" :class="$style.addIcon" />
        <span>添加枚举项（最多20个）</span>
      </span>
    </div>
  </div>
</template>
<script>
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import NumberRange from './number-range.vue';
import { createFormModal } from '@triascloud/x-components';
import AddEnumLanguage from './add-enum-language.vue';
import { formatList, listByMap } from '../param-form.vue';

@Component({
  components: {
    NumberRange,
  },
})
export default class EnumNumberRange extends Vue {
  @Model('change.value') value;
  @Prop({ type: Array }) value;

  list = [
    {
      number: [],
      multiLanguage: [],
    },
  ];

  handleDeleteItem(index) {
    this.list.splice(index, 1);
    this.handleChange();
  }
  async handleAddLanguage(item) {
    let detail = [];
    if (item && item.multiLanguage) {
      detail = listByMap(item.multiLanguage, 'language');
    }
    if (item.number.length === 0) return false;
    const result = await createFormModal(
      () => <AddEnumLanguage detail={detail} />,
      {
        width: 400,
        title: `选项(${item.number[0]})多语言`,
      },
    );
    const map = formatList(result, 'language');
    this.$set(item, 'multiLanguage', map);
  }
  handleAddItem() {
    if (this.list.length >= 20) {
      this.$message.warn('不准再添加');
      return false;
    }
    this.list.push({
      number: [],
      multiLanguage: [],
    });
    this.handleChange();
  }

  handleChange() {
    this.$emit('change.value', this.list);
    this.$emit('change', this.list);
    this.$emit('input', this.list);

    this.showErrorTooltip();
  }

  // 表单校验样式检查
  showErrorTooltip() {
    try {
      this.$nextTick(() => {
        this.list.forEach((val, index) => {
          this.$refs[`ref${index}`][0].findClass();
        });
      });
    } catch (error) {
      return;
    }
  }

  /**
   * 1、value 数据初始化
   * 2、数据改变 egg: 通过接口获取的
   */
  @Watch('value', { immediate: true, deep: true })
  onValueWatch(value) {
    this.list = value;
  }
}
</script>
<style lang="less" module>
.labelBox {
  display: flex;

  .label {
    flex: 1;
  }

  .label + .label {
    margin-left: 20px;
  }
}
.formBox + .formBox {
  margin-top: 10px;
}
.formBox {
  display: flex;
  align-items: center;

  .item {
    flex: 1;
    margin-right: 10px;
  }
  .icon + .icon {
    margin-left: 10px;
  }
  .icon {
    font-size: 20px;
    cursor: pointer;
  }
  .span {
    display: inline-block;
    width: 20px;
  }
}
.add {
  color: var(--primary);
  cursor: pointer;
  padding: 4px 0;
  font-size: 14px;
  .addIcon {
    font-size: 16px;
    margin-right: 10px;
  }
}
</style>
