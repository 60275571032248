<template>
  <div>
    <div :class="$style.form">
      <a-form-model ref="ruleForm" layout="inline">
        <a-form-model-item>
          <a-input
            style="width: 240px"
            v-model="query"
            @input.stop="input => handleSearch(input)"
            placeholder="搜索"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
        </a-form-model-item>
      </a-form-model>
      <div>
        <a-button type="primary" @click="handleAddParam">添加参数</a-button>
      </div>
    </div>
    <a-table
      :columns="columns"
      :dataSource="list"
      :pagination="pagination"
      @change="handleChange"
    >
      <template slot="action" slot-scope="text, record">
        <span :class="$style.btn" @click.stop="handleEdit(record)">编辑</span>
        <span :class="$style.btn" @click.stop="handleDelete(record)">删除</span>
      </template>
      <template slot="declareSlot" slot-scope="text, record">
        {{ formatDeclare(record.dataType.dataType, record.dataType.specs) }}
      </template>
    </a-table>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Button } from 'ant-design-vue';
import { Debounce } from 'lodash-decorators';
import { createFormModal, createModal } from '@triascloud/x-components';
import ParamForm from './param-form.vue';
import Tip from '@/components/tip/index';
import {
  getList,
  insertParam,
  deleteParam,
  editParam,
} from '@/services/monitor/kolb';
import { types, DATATYPE } from './final';

@Component()
export default class Params extends Vue {
  @Prop({ type: String, default: '' }) productId;

  created() {
    this.getData();
  }

  query = '';
  @Debounce(200)
  handleSearch(input) {
    this.query = input.target.value;
    this.pagination.current = 1;
    this.getData();
  }

  async handleAddParam() {
    try {
      const result = await createFormModal(() => <ParamForm />, {
        width: 470,
        title: '参数添加',
      });
      await insertParam({
        ...result,
        idxProductId: this.productId,
      });
      this.$message.success('参数添加成功');
      this.getData();
    } catch (error) {
      if (error.message !== 'user cancel') {
        this.$message.error('参数添加失败');
      }
      return false;
    }
  }
  async handleEdit(detail) {
    try {
      const result = await createFormModal(
        () => <ParamForm detail={detail} />,
        {
          width: 470,
          title: '编辑参数',
        },
      );
      await editParam({
        ...result,
        id: detail.pkId,
        idxProductId: this.productId,
      });
      this.$message.success('参数编辑成功');
      this.getData();
    } catch (error) {
      if (error.message !== 'user cancel') {
        this.$message.error('参数编辑失败');
      }
    }
  }
  async handleDelete(record) {
    try {
      const result = await createFormModal(
        () => (
          <Tip>
            <span slot="txt">确认删除当前参数？</span>
            <span slot="subTxt">删除后将无法恢复数据！</span>
          </Tip>
        ),
        {
          width: 470,
          title: '重要提示',
        },
      );
      if (result) {
        await deleteParam(record.pkId);
        this.$message.success('参数删除成功');
        this.getData();
      }
    } catch (error) {
      if (error.message !== 'user cancel') {
        const value = createModal(
          () => (
            <Tip>
              <span slot="txt">当前参数已被菜单引用，不允许删除！</span>
            </Tip>
          ),
          // <span slot="subTxt">引用菜单名称：自定义菜单XXX</span>
          {
            width: 470,
            title: '重要提示',
            footer: h => [
              h(
                Button,
                {
                  props: { type: 'primary' },
                  on: {
                    click: () => value.handleClose(),
                  },
                },
                '确定',
              ),
            ],
          },
        );
      }
    }
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: '序号',
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        title: '参数名称',
        dataIndex: 'parameterName',
        ellipsis: true,
      },
      {
        title: '标识符号',
        dataIndex: 'identifier',
        ellipsis: true,
      },
      {
        title: '数据类型',
        dataIndex: 'type',
      },
      {
        title: '数据定义',
        dataIndex: 'declare',
        ellipsis: true,
        scopedSlots: {
          customRender: 'declareSlot',
        },
      },
      {
        title: '单位',
        dataIndex: 'unit',
      },
      {
        title: '备注',
        dataIndex: 'remark',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '操作',
        fixed: 'right',
        width: 200,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  list = [];
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  handleChange(pagination) {
    this.pagination.current = pagination.current;
    this.getData();
  }
  async getData() {
    const { records, current, total } = await getList({
      productId: this.productId,
      input: this.query,
      pageNum: this.pagination.current,
      pageSize: this.pagination.pageSize,
    });
    this.list = this.formatData(records);
    this.pagination.current = current;
    this.pagination.total = total;
  }
  formatData(list = []) {
    return list.map(v => {
      return {
        ...v,
        unit: v.dataType.specs.unit,
        type: types.find(i => i.key === v.dataType.dataType).value,
      };
    });
  }
  /** @name 数据定义  */
  formatDeclare(type = '', obj = {}) {
    switch (type) {
      case DATATYPE.int:
      case DATATYPE.float:
        return `取值范围：${obj.min}-${obj.max}`;
      case DATATYPE.text:
        return `取值范围：${obj.textLength}`;
      case DATATYPE.boolean:
        return `0-${obj.boolValue[0]}，1-${obj.boolValue[1]}`;
      case DATATYPE.enum:
        return this.formatEnum(obj.enumValue);
      default:
        return '';
    }
  }
  formatEnum(obj) {
    let str = '';
    Object.keys(obj).forEach(v => {
      str += `${v}-${obj[v]}，`;
    });
    return str.slice(0, -1);
  }
}
</script>
<style lang="less" module>
.form {
  display: flex;
  justify-content: space-between;
}
.btn {
  color: var(--primary);
  cursor: pointer;
  font-size: 14px;
}
.btn + .btn {
  margin-left: 26px;
}
</style>
