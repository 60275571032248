var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-upload',{attrs:{"list-type":"picture-card","file-list":_vm.images,"customRequest":_vm.customUpload,"accept":"image/*","disabled":_vm.isDisable},on:{"preview":_vm.handlePreview,"change":_vm.handleImgsChange}},[(_vm.images.length < _vm.maxCount && !_vm.isDisable)?_c('div',[_c('a-icon',{attrs:{"type":"plus"}})],1):_vm._e()]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handlePreviewCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})]),_c('a-modal',{attrs:{"visible":_vm.imageEditVisible,"maskClosable":false,"width":530},on:{"ok":_vm.handleImgResize,"cancel":_vm.handleCancel}},[_c('div',{staticStyle:{"min-height":"300px"}},[_c('cropper',{ref:"cropper",attrs:{"src":_vm.previewImage,"stencil-size":{
          width: 300,
          height: 300,
        },"stencil-props":{
          handlers: {},
          movable: false,
          resizable: false,
          aspectRatio: 1,
        },"image-restriction":"stencil"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }