<template>
  <div>
    <a-row :gutter="20">
      <a-col :span="8">
        <div :class="[$style.title, $style.iconTitle]">
          <span>菜单列表</span>
          <span :class="$style.iconAdd">
            <span v-if="singleClickFlag === 'add'">
              <span v-if="groupClickFlag === 'add'" @click="addForGroup">
                添加为参数集合
              </span>
              <span v-else @click="addForGroup">
                修改该参数集合
              </span>
            </span>
            <span v-if="groupClickFlag === 'add'">
              <span
                v-if="singleClickFlag === 'add'"
                @click="addForSingle"
                style="margin-left: 10px"
              >
                添加为单个参数
              </span>
              <span v-else @click="addForSingle" style="margin-left: 10px"
                >修改该单个参数</span
              >
            </span>
          </span>
        </div>
        <div :class="$style.divHeight">
          <div ref="card" :class="$style.menuBox">
            <draggable
              tag="div"
              v-model="menuList"
              v-bind="viewOptions"
              chosen-class="chosen"
              handle=".iconMove"
            >
              <div
                v-for="(item, index) in menuList"
                :key="index"
                :class="[
                  $style.menuItem,
                  item.checked ? $style.activeMenuItem : '',
                ]"
                @click.stop="handleItem(item, index)"
              >
                <div :class="$style.leftItem">
                  <x-icon
                    class="iconMove"
                    :class="$style.moveIcon"
                    type="tc-icon-drag-handler"
                  />
                  <span :class="$style.text" :title="item.name">
                    {{ item.name }}
                  </span>
                </div>
                <div>
                  <x-icon
                    :class="$style.deleteIcon"
                    type="tc-icon-delete"
                    @click.stop="deleteMenu(index)"
                  />
                  <a-icon
                    v-if="item.flag === 'group'"
                    type="right"
                    style="margin-right: 5px"
                  />
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div :class="[$style.title, $style.iconTitle]">
          <span>选择功能参数</span>
          <a-icon @click="handleAddParam" type="plus" :class="$style.iconAdd" />
        </div>
        <div :class="$style.divHeight">
          <div :class="$style.search">
            <a-input-search
              placeholder="参数名称/标识符"
              @change="input => onSearch(input)"
            />
          </div>
          <div :class="$style.list">
            <div :class="$style.listBox" v-if="showList.length > 0">
              <div
                v-for="(item, idx) in showList"
                :key="idx"
                :class="[
                  $style.chooseItem,
                  item.checked ? $style.checkedItem : '',
                ]"
                @click.stop="chooseItem(item, idx)"
              >
                <div>
                  <div>{{ item.parameterName }}</div>
                  <div>标识符：{{ item.identifier }}</div>
                </div>
                <a-icon
                  type="check-circle"
                  :class="[item.checked ? $style.checked : $style.unChecked]"
                />
              </div>
            </div>
            <div :class="$style.noDataBox" v-else>
              <EmptyContent />
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div :class="$style.rightTitle">
          <span :class="$style.title">已选功能参数</span>
          <span :class="$style.operation" @click.stop="clearAllSelected"
            >全部清空</span
          >
        </div>
        <div :class="$style.divHeight">
          <div :class="$style.list">
            <div :class="$style.listBox">
              <div
                v-for="(item, idx) in selectedList"
                :key="idx"
                :class="[$style.chooseItem, $style.deleteItem]"
              >
                <div>
                  <div>{{ item.parameterName }}</div>
                  <div>标识符：{{ item.identifier }}</div>
                </div>
                <div @click.stop="removeSelectedItem(item, idx)">
                  <x-icon type="tc-icon-delete" :class="$style.deleteIcon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import { deepClone } from '@triascloud/utils';
import { createFormModal } from '@triascloud/x-components';
import AddParameterGroup from './add-parameter-Group';
import { v4 as uuidv4 } from 'uuid';
import { getListAll, insertParam } from '@/services/monitor/kolb';
import ParamForm from '../params/param-form.vue';
import EmptyContent from '@/components/empty-content';
import { Debounce } from 'lodash-decorators';

@Component({
  components: {
    Draggable,
    AddParameterGroup,
    EmptyContent,
  },
})
export default class CustomSetting extends Vue {
  @Prop({ type: String, default: '' }) productId;
  @Prop({ type: Array, default: () => [] }) settingData;

  currentItemIdx = -1;
  viewOptions = {
    animation: 300,
    group: 'viaCamera',
    ghostClass: 'ghost',
    scroll: true,
  };
  groupClickFlag = 'add';
  singleClickFlag = 'add';
  mounted() {
    this.getParamsData();
    this.getMenuList();
  }
  parameterList = [];
  showList = [];
  menuList = [];
  nameList = [];
  getMenuList() {
    this.nameList = [];
    // this.menuList = [
    //   {
    //     checked: false,
    //     name: '默认测试1',
    //     flag: 'group',
    //     list: [
    //       {
    //         id: 1,
    //         name: '预热T1',
    //         sign: 'PreHeatTemp1',
    //       },
    //       {
    //         id: 3,
    //         name: '预热T3',
    //         sign: 'PreHeatTemp3',
    //       },
    //     ],
    //   },
    //   {
    //     checked: false,
    //     name: '默认测试2',
    //     flag: 'group',
    //     list: [
    //       {
    //         id: 5,
    //         name: '预热T5',
    //         sign: 'PreHeatTemp5',
    //       },
    //       {
    //         id: 6,
    //         name: '预热T6',
    //         sign: 'PreHeatTemp6',
    //       },
    //     ],
    //   },
    // ];
    // this.menuList.forEach(v => {
    //   this.nameList.push(v.name);
    // });
  }
  @Watch('settingData', { immediate: true, deep: true })
  changeData(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.menuList = newVal.map(v => {
        return {
          checked: false,
          name: v.menuFunctionName,
          flag: v.isArray ? 'group' : 'single',
          id: v.id,
          list: v.parameterListDetails,
        };
      });
    }
  }
  selectedList = [];
  selectedItem = {};
  handleItem(item, index) {
    this.groupClickFlag = 'add';
    this.singleClickFlag = 'add';
    this.selectedItem = item;
    this.currentItemIdx = index;
    this.menuList.map(v => {
      if (v.id !== item.id) {
        v.checked = false;
      }
    });
    this.showList.map(v => (v.checked = false));
    if (!item.checked) {
      item.flag === 'group'
        ? (this.groupClickFlag = 'edit')
        : (this.singleClickFlag = 'edit');
      item.checked = true;
      this.selectedList = deepClone(item.list);
      item.list.forEach(v => {
        this.showList.map(t => {
          if (v.id === t.id) {
            return (t.checked = true);
          }
        });
      });
    } else {
      item.checked = false;
      item.flag === 'group'
        ? (this.groupClickFlag = 'add')
        : (this.singleClickFlag = 'add');
      this.selectedList = [];
      this.selectedItem = {};
    }
  }
  @Debounce(300)
  onSearch(e) {
    const val = e.target.value;
    const input = val.toLowerCase();
    this.showList = this.parameterList.filter(
      v =>
        v.parameterName.toLowerCase().indexOf(input) >= 0 ||
        v.identifier.toLowerCase().indexOf(input) >= 0,
    );
  }
  chooseItem(item, idx) {
    const t = this.showList[idx];
    t.checked = !t.checked;
    if (t.checked) {
      this.selectedList.push({
        id: item.id,
        parameterName: item.parameterName,
        identifier: item.identifier,
      });
    } else {
      const index = this.selectedList.findIndex(v => v.id === item.id);
      this.selectedList.splice(index, 1);
    }
  }
  clearAllSelected() {
    this.selectedList = [];
    this.showList.map(item => (item.checked = false));
  }
  removeSelectedItem(row, index) {
    this.selectedList.splice(index, 1);
    const idx = this.showList.findIndex(v => v.id === row.id);
    this.showList[idx].checked = false;
  }
  async addForGroup() {
    const selectedList = this.selectedList;
    if (selectedList.length === 0) {
      this.$message.warning('请至少选择一个功能参数');
      return;
    }
    const nameList = this.menuList.map(v => v.id);
    const item = this.selectedItem;
    const result = await createFormModal(
      () => <AddParameterGroup editName={item.name} nameList={nameList} />,
      {
        width: '500px',
        title: '参数集合名称',
      },
    );
    const uuid = uuidv4();
    this.nameList.push(uuid);
    const menu = {
      id: uuid,
      flag: 'group',
      name: result.parameterName,
      checked: false,
      list: selectedList,
    };
    if (nameList.indexOf(item.id) > -1) {
      const idx = this.menuList.findIndex(v => v.id === item.id);
      this.menuList.splice(idx, 1, menu);
    } else {
      this.menuList.push(menu);
    }
    this.selectedList = [];
    this.showList.map(item => (item.checked = false));
  }
  addForSingle() {
    const list = this.selectedList;
    const row = this.selectedItem;
    if (list.length === 0 || list.length > 1) {
      this.$message.warning('请选择一个功能参数');
      return;
    }
    const menu = {
      id: uuidv4(),
      flag: 'single',
      name: list[0].parameterName,
      checked: false,
      list: list,
    };
    if (this.singleClickFlag === 'edit') {
      const idx = this.menuList.findIndex(v => v.id === row.id);
      menu.id = row.id;
      this.menuList.splice(idx, 1, menu);
    } else {
      this.menuList.push(menu);
    }
    this.selectedList = [];
    this.showList.map(item => (item.checked = false));
  }
  deleteMenu(index) {
    this.menuList.splice(index, 1);
    this.selectedList = [];
    this.showList.map(item => (item.checked = false));
  }
  getValue() {
    let sum = 0;
    const settingObj = {};
    const menuFunction = this.menuList.map(item => {
      sum += item.list.length;
      return {
        id: item.id ? item.id : undefined,
        isArray: item.flag === 'group',
        menuFunctionName: item.name,
        parameterList: item.list.map(item => item.id),
      };
    });
    settingObj.sum = sum;
    settingObj.menuFunction = menuFunction;
    return settingObj;
  }

  async handleAddParam() {
    try {
      const result = await createFormModal(() => <ParamForm />, {
        width: 470,
        title: '参数添加',
      });
      await insertParam({
        ...result,
        idxProductId: this.productId,
      });
      this.$message.success('参数添加成功');
      await this.getParamsData();
    } catch (error) {
      if (error.message !== 'user cancel') {
        this.$message.error('参数添加失败');
      }
      return false;
    }
  }
  async getParamsData() {
    const data = await getListAll({
      productId: this.productId,
    });
    this.parameterList = this.formatData(data);
    this.showList = deepClone(this.parameterList);
  }
  formatData(list = []) {
    return list.map(v => {
      return {
        id: v.pkId,
        parameterName: v.parameterName,
        identifier: v.identifier,
        checked: false,
      };
    });
  }
}
</script>

<style lang="less" module>
.title {
  color: var(--font-info);
}
.divHeight {
  height: 520px;
  border: 1px solid var(--border);
  border-radius: 5px;
  padding: 5px 0;
  .menuItem {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: var(--block-bg);
    border: solid 1px var(--form-border);
    margin-bottom: 10px;
    cursor: pointer;
    justify-content: space-between;
    &:hover {
      background-color: var(--inline-menu-active);
      .deleteIcon {
        opacity: 1;
      }
    }
    .leftItem {
      display: flex;
      align-items: center;
      .text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 200px;
      }
    }
    .chosen {
      border: solid 2px #3089dc !important;
    }
    .moveIcon {
      cursor: move;
      font-size: 24px;
      margin-right: 10px;
    }
    .deleteIcon {
      opacity: 0;
      font-size: 18px;
      margin-right: 8px;
      cursor: pointer;
      color: var(--primary);
      &:last-child {
        margin-right: 12px;
      }
    }
  }
  .activeMenuItem {
    background-color: var(--inline-menu-active);
    color: var(--font-active);
  }
}
.search {
  padding: 10px 5px;
  width: 295px;
}
.list {
  overflow-y: auto;
  height: 460px;
  .listBox {
    padding: 0 5px;
    width: 295px;
  }
}
.rightTitle {
  display: flex;
  justify-content: space-between;
}
.operation {
  cursor: pointer;
  color: var(--primary);
}
.menuBox {
  margin-top: 10px;
  height: 500px;
  padding: 0 10px;
  overflow-y: auto;
}
.checked {
  display: block;
  color: var(--primary);
}
.unChecked {
  display: none;
}
.checkedItem {
  background-color: var(--inline-menu-active);
}
.chooseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--form-border);
  margin: 5px 0;
  padding: 0 10px;
  cursor: default;
  &:hover {
    background-color: var(--inline-menu-active);
  }
}
.deleteIcon {
  opacity: 0;
  cursor: pointer;
}
.deleteItem {
  &:hover {
    .deleteIcon {
      opacity: 1;
      color: var(--primary);
    }
  }
}
.btnList {
  padding: 5px;
  display: flex;
  justify-content: center;
}
.iconTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .iconAdd {
    cursor: pointer;
    color: var(--primary);
  }
}
</style>
