<script>
import { Component, Model, Vue, Prop, Watch } from 'vue-property-decorator';
const typeFn = t => t.replace(/^\S/, s => s.toUpperCase());
@Component()
export default class XRange extends Vue {
  form = {
    min: undefined,
    max: undefined,
  };

  @Model('change.value') value;
  @Prop({ type: Array }) value;
  @Prop({ type: Boolean, default: false }) flex;
  @Prop({
    type: Object,
    default: () => {
      return {
        left: {
          type: 'number',
          placeholder: '最小值',
          step: 1,
        },
        className: '',
        right: {
          type: 'text',
          placeholder: '最大值',
          step: 1,
        },
        middle: {
          txt: '至',
          style: {
            fontSize: '12px',
            margin: '0 10px',
          },
        },
      };
    },
  })
  cmp;

  /** @name 是否需要单项表单检查 */
  @Prop({ type: Boolean, default: true }) hasCheck;
  hasError = false;
  findClass() {
    if (!this.hasCheck) {
      return false;
    }
    setTimeout(() => {
      try {
        // 获取这个元素<div class="ant-form-item-control has-error"></div>的 classlist ,来比较
        const array = this.domClassList;
        const result = array.find(v => v === 'has-error');
        if (result) {
          this.hasError = true;
        } else {
          this.hasError = false;
        }
      } catch (error) {
        this.hasError = false;
        return false;
      }
    }, 20);
  }
  get domClassList() {
    try {
      let el = this.$refs.boxRef.parentNode;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const array = Array.from(el.classList);
        const result = array.find(v => v === 'ant-form-item-control');
        if (result) {
          break;
        } else {
          el = el.parentNode;
        }
      }
      return Array.from(el.classList);
    } catch (error) {
      return [];
    }
  }

  handleChange(event, type) {
    let result = [];
    if (type === 'min') {
      result[0] = event;
      result[1] = this.formatData(this.form.max);
      this.form.min = event;
    } else {
      result[0] = this.formatData(this.form.min);
      result[1] = event;
      this.form.max = event;
    }
    this.$emit('change.value', result);
    this.$emit('change', result);
    this.$emit('input', result);

    this.findClass();
  }

  formatData(val) {
    if (typeof val === 'number') {
      return val;
    } else {
      return val || undefined;
    }
  }

  @Watch('value', { deep: true, immediate: true })
  onValueWatch(value) {
    if (!value) return false;
    this.form.min = value.length > 0 ? value[0] : undefined;
    this.form.max = value.length > 1 ? value[1] : undefined;
  }

  renderLeft() {
    const type = typeFn(this.cmp.left.type);
    return this[`render${type}`](
      {
        model: this.form.min,
        type: 'min',
        className: this.cmp.className,
      },
      {
        placeholder: this.cmp.left.placeholder,
        step: this.cmp.left.step,
      },
    );
  }
  renderRight() {
    const type = typeFn(this.cmp.right.type);
    return this[`render${type}`](
      {
        model: this.form.max,
        type: 'max',
        className: this.cmp.className,
      },
      {
        placeholder: this.cmp.right.placeholder,
        step: this.cmp.right.step,
        min: this.form.min ? this.form.min + this.cmp.right.step : 0,
      },
    );
  }
  renderText(obj, props) {
    return (
      <a-input
        {...{ props }}
        class={[
          this.$style.input,
          this.flex ? this.$style.inputFlex : '',
          this.hasError && obj.model ? this.$style.successColor : '',
          obj.className,
        ]}
        v-model={obj.model}
        onChange={event => this.handleChange(event.target.value, obj.type)}
      ></a-input>
    );
  }
  renderNumber(obj, props) {
    return (
      <a-input-number
        {...{ props }}
        class={[
          obj.className,
          this.$style.input,
          this.flex ? this.$style.inputFlex : '',
          this.hasError && obj.model ? this.$style.successColor : '',
        ]}
        v-model={obj.model}
        onChange={event => this.handleChange(event, obj.type)}
      ></a-input-number>
    );
  }

  render() {
    return (
      <div
        class={[this.$style.formBox, this.flex ? this.$style.formFlex : '']}
        ref={'boxRef'}
      >
        {this.renderLeft()}
        <span class={this.$style.label} style={this.cmp.middle.style}>
          {this.cmp.middle.txt}
        </span>
        {this.renderRight()}
      </div>
    );
  }
}
</script>
<style lang="less" module>
.formBox {
  width: 100%;
  display: block;

  &.formFlex {
    display: flex;
    align-items: center;
  }

  .input {
    display: inline-block;
    width: 90px;
    &.inputFlex {
      flex: 1;
    }
  }

  .label {
    width: 34px;
    text-align: center;
    font-size: 14px;
    display: inline-block;
  }

  .successColor {
    border-color: var(--form-border);
    box-shadow: 0 0 0 2px var(--primary-fade-20);

    &:hover,
    &:focus,
    &:not([disabled]):hover {
      border-color: var(--primary-80);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--primary-fade-20);
      border-color: var(--primary-80);
    }
  }
}
</style>
