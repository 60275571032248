<template>
  <a-form-model
    class="devLoginForm"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-form-model-item label="你好开发者，请输入开发密码：" prop="productSign">
      <a-input
        placeholder="请输入密码"
        v-model="form.password"
        type="password"
        :maxLength="64"
      />
    </a-form-model-item>
    <a-form-model-item style="text-align: center;">
      <a-button type="primary" @click="handleSubmit">登录</a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import md5 from 'js-md5';
import { checkPwd } from '@/services/monitor/kolb';
import { message } from 'ant-design-vue';

@Component({
  components: {},
})
export default class DevelopLoginForm extends Vue {
  form = {
    password: '',
  };
  rules = {
    password: [
      {
        required: true,
        message: '请输入密码',
      },
    ],
  };

  async handleSubmit() {
    try {
      const result = await this.$refs.form.validate();
      if (result) {
        const pwd = await checkPwd(md5(this.form.password));
        if (!pwd) {
          message.error('密码不正确！');
        } else {
          this.$emit('result', pwd);
        }
      }
    } catch {
      return false;
    }
  }
}
</script>
