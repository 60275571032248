<template>
  <div :class="$style.formBox" ref="boxRef">
    <a-input
      :class="[$style.input, hasError && form.value ? $style.successColor : '']"
      v-model="form.value"
      placeholder='编号如"0"'
      @input="event => handleChange(event, 'value')"
    ></a-input>
    <a-input
      :class="[$style.input, hasError && form.desc ? $style.successColor : '']"
      v-model="form.desc"
      placeholder="对枚举项的描述"
      @input="event => handleChange(event, 'desc')"
    ></a-input>
  </div>
</template>
<script>
import { Component, Model, Vue, Prop, Watch } from 'vue-property-decorator';
@Component()
export default class NumberRangeItem extends Vue {
  form = {
    value: '',
    desc: '',
  };

  @Model('change.value') value;
  @Prop({ type: Array }) value;

  handleChange(event, type) {
    let result = [];
    if (type === 'value') {
      result[0] = event.target.value;
      result[1] = this.form.desc || '';
    } else {
      result[0] = this.form.value || '';
      result[1] = event.target.value;
    }
    this.$emit('change.value', result);
    this.$emit('change', result);
    this.$emit('input', result);
    this.findClass();
  }

  @Watch('value', { deep: true, immediate: true })
  onValueWatch(value) {
    if (!value) return false;
    this.form.value = value.length > 0 ? value[0] : '';
    this.form.desc = value.length > 1 ? value[1] : '';
  }

  /** @name 是否需要单项表单检查 */
  @Prop({ type: Boolean, default: false }) hasCheck;
  hasError = false;
  findClass() {
    if (!this.hasCheck) {
      return false;
    }
    setTimeout(() => {
      try {
        // 获取这个元素<div class="ant-form-item-control has-error"></div>的 classlist ,来比较
        const array = this.domClassList;
        const result = array.find(v => v === 'has-error');
        if (result) {
          this.hasError = true;
        } else {
          this.hasError = false;
        }
      } catch (error) {
        this.hasError = false;
        return false;
      }
    }, 20);
  }
  get domClassList() {
    try {
      let el = this.$refs.boxRef.parentNode;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const array = Array.from(el.classList);
        const result = array.find(v => v === 'ant-form-item-control');
        if (result) {
          break;
        } else {
          el = el.parentNode;
        }
      }
      return Array.from(el.classList);
    } catch (error) {
      return [];
    }
  }
}
</script>
<style lang="less" module>
.formBox {
  display: flex;
  width: 100%;

  .input {
    flex: 1;
  }

  .input + .input {
    margin-left: 20px;
  }

  .successColor {
    border-color: var(--form-border);

    &:hover,
    &:focus,
    &:not([disabled]):hover {
      border-color: var(--primary-80);
    }
    &:focus {
      box-shadow: 0 0 0 2px var(--primary-fade-20);
      border-color: var(--primary-80);
    }
  }
}
</style>
