export const languages = [
  {
    id: '00',
    name: '英语（美）-00',
  },
  {
    id: '01',
    name: '英语（英）-01',
  },
  {
    id: '02',
    name: '德语-02',
  },
  {
    id: '03',
    name: '西班牙语-03',
  },
  {
    id: '04',
    name: '瑞典语-04',
  },
  {
    id: '05',
    name: '法语-05',
  },
  {
    id: '06',
    name: '丹麦语-06',
  },
  {
    id: '07',
    name: '俄罗斯语-07',
  },
  {
    id: '08',
    name: '波兰语-08',
  },
  {
    id: '09',
    name: '土耳其语-09',
  },
  {
    id: '10',
    name: '意大利语-10',
  },
  {
    id: '11',
    name: '克罗地亚语-11',
  },
  {
    id: '12',
    name: '捷克语-12',
  },
  {
    id: '13',
    name: '挪威语-13',
  },
  {
    id: '14',
    name: '匈牙利语-14',
  },
  {
    id: '16',
    name: '日文-16',
  },
  {
    id: '17',
    name: '韩文-17',
  },
];
