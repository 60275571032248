<template>
  <div :class="$style.box">
    <section
      :class="$style.item"
      v-for="(item, index) in list"
      :key="'language' + index"
    >
      <a-language
        hasCheck
        :defaultValue="defaultValueFetch(index)"
        :ref="'ref' + index"
        :class="$style.itemMain"
        v-model="item.language"
        @change="event => handleChange(event, index)"
      />
      <x-icon
        type="tc-icon-delete"
        :class="$style.icon"
        @click.stop="handleDeleteItem(index)"
      />
    </section>
    <div :class="$style.add">
      <span @click="handleAddItem">
        <x-icon type="tc-icon-icon-test1" :class="$style.addIcon" />
        <span>添加多语言</span>
      </span>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator';
import language from './language.vue';
import { languages } from './final';

@Component({
  components: {
    'a-language': language,
  },
})
export default class LanguageList extends Vue {
  @Model('change.value', { type: Array }) value;
  @Prop({ type: Array }) value;

  list = [];

  handleDeleteItem(index) {
    this.list.splice(index, 1);

    this.handleChange();
  }
  handleAddItem() {
    this.list.push({
      language: [],
    });

    this.handleChange();
  }

  /**
   * @name 多语言选择不重复
   */
  defaultValueFetch(index) {
    let array = [];
    let noArray = [];
    this.list.forEach((item, itemIndex) => {
      if (index !== itemIndex) {
        if (item.language.length > 0 && item.language[0]) {
          const language = languages.find(v => v.id === item.language[0]);
          noArray.push(language);
        }
      }
    });
    languages.forEach(item => {
      if (!noArray.find(v => v.id === item.id)) {
        array.push(item);
      }
    });
    return array;
  }

  handleChange() {
    this.$emit('change.value', this.list);
    this.$emit('change', this.list);
    this.$emit('input', this.list);

    this.showErrorTooltip();
  }

  // 表单校验样式检查
  showErrorTooltip() {
    try {
      this.$nextTick(() => {
        this.list.forEach((val, index) => {
          this.$refs[`ref${index}`][0].findClass();
        });
      });
    } catch (error) {
      return;
    }
  }

  /**
   * 1、value 数据初始化
   * 2、数据改变 egg: 通过接口获取的
   */
  @Watch('value', { immediate: true, deep: true })
  onValueWatch(value) {
    this.list = value;
  }
}
</script>
<style lang="less" module>
.box {
  .item + .item {
    margin-top: 10px;
  }
  .item {
    display: flex;
    align-items: center;

    .itemMain {
      flex: 1;
      margin-right: 10px;
    }
    .icon {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .add {
    color: var(--primary);
    cursor: pointer;
    padding: 4px 0;
    font-size: 14px;
    .addIcon {
      font-size: 16px;
      margin-right: 10px;
    }
  }
}
</style>
