<template>
  <div class="card">
    <div class="form">
      <a-form-model
        class="settingsBoxWrapper"
        layout="vertical"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <div class="baseSetBox">
          <a-form-model-item label="产品标识" prop="productSign">
            <a-input
              placeholder="请输入产品标识"
              :disabled="!canEdit"
              v-model="form.productSign"
              :maxLength="64"
            />
          </a-form-model-item>
          <a-form-model-item label="产品分类" prop="productType">
            <a-select
              v-model="form.productType"
              placeholder="请选择产品分类"
              @change="handleProductType"
              :disabled="!canEdit"
            >
              <a-select-option
                :value="item.id"
                v-for="item in productTypes"
                :key="item.id"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="软件版本" prop="version">
            <a-radio-group name="version" v-model="form.version" disabled>
              <a-radio
                v-for="item in productVersion"
                :value="item.id"
                :key="item.id"
              >
                {{ item.label }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="产品头图" prop="images">
            <images-upload v-model="form.images" :maxCount="5"></images-upload>
          </a-form-model-item>
          <a-form-model-item label="访问路由" prop="routeFileName">
            <div class="hFlex">
              <a-input placeholder="IP/域名" disabled />
              <span style="margin: 0 10px">+</span>
              <a-input
                v-model="form.routeFileName"
                placeholder="/?filename"
                :maxLength="64"
              />
              <span style="margin: 0 10px">+</span>
              <a-input placeholder="关联文件名称" disabled />
            </div>
            <span class="textTips"
              >例如：10.168.1.10/?filename=settings.txt</span
            >
          </a-form-model-item>
          <a-form-model-item label="备注说明" prop="remark">
            <a-input
              :maxLength="32"
              placeholder="请输入备注说明"
              :disabled="!canEdit"
              v-model="form.remark"
            />
          </a-form-model-item>
        </div>
        <div class="baseSetBox">
          <a-form-model-item label="关联文件" prop="settingFiles">
            <recipes-upload
              v-model="form.settingFiles"
              :maxCount="12"
            ></recipes-upload>
          </a-form-model-item>
        </div>
      </a-form-model>
      <div class="button">
        <a-button type="primary" @click="saveBaseCfg">保存</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';
import ImagesUpload from '../../images-upload.vue';
import RecipesUpload from '../../recipes-upload.vue';
import 'vue-advanced-cropper/dist/style.css';
import {
  getProductBaseCfg,
  getProductInfo,
  saveProductBaseCfg,
} from '@/services/oven/product';

@Component({ components: { ImagesUpload, RecipesUpload } })
export default class BaseSettings extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) productId;

  canEdit = true;
  imageEditVisible = false;
  form = {
    productSign: '',
    productType: undefined,
    images: [
      // {
      //   uid: '-1',
      //   name: 'image.png',
      //   status: 'done',
      //   url:
      //     'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // },
    ],
    settingFiles: [
      {
        fileName: 'menue.txt',
        menuName: '食谱设置',
        canEdit: false,
        relatedFilesEnum: 'RECIPE_SETTINGS',
      },
      {
        fileName: 'favorites.txt',
        menuName: '收藏管理',
        canEdit: false,
        relatedFilesEnum: 'COLLECT_MANAGE',
      },
      {
        fileName: 'status.txt',
        menuName: '设备状态',
        canEdit: false,
        relatedFilesEnum: 'DEVICE_STATUS',
      },
      {
        fileName: 'haccp_this_month.txt',
        menuName: '本月日志',
        canEdit: false,
        relatedFilesEnum: 'THIS_MONTH_LOGGER',
      },
      {
        fileName: 'haccp_last_month.txt',
        menuName: '上月日志',
        canEdit: false,
        relatedFilesEnum: 'LAST_MONTH_LOGGER',
      },
      {
        fileName: 'warnings.txt',
        menuName: '设备预警',
        canEdit: false,
        relatedFilesEnum: 'DEVICE_WARNING',
      },
    ],
    operateGuide: '',
    officialInfo: '',
    routeFileName: '',
    remark: '',
  };
  checkFiles(rule, value, callback) {
    let hasEmptyFile = false;
    let hasWrongName = false;
    value.forEach(item => {
      if (!item.fileName) {
        hasEmptyFile = true;
      } else if (!/[\w]+(.txt)$/g.test(item.fileName)) {
        hasWrongName = true;
      }
    });
    if (hasEmptyFile) {
      return callback(new Error('关联文件不能为空'));
    } else if (hasWrongName) {
      return callback(new Error('文件名需要以.txt结尾'));
    } else {
      return callback();
    }
  }
  imageUrl = '';
  rules = {
    productSign: [
      {
        required: true,
        message: '产品标识不能为空',
      },
    ],
    productType: [
      {
        required: true,
        message: '产品分类不能为空',
      },
    ],
    images: [
      {
        required: true,
        message: '产品头图不能为空',
      },
    ],
    settingFiles: [
      {
        required: true,
        // message: '关联文件不能为空',
        validator: this.checkFiles,
        trigger: 'change',
      },
    ],
    routeFileName: [
      {
        required: true,
        message: '访问路由不能为空',
      },
    ],
  };
  productTypes = [];
  productVersion = [];
  mounted() {
    this.getProductInfos();
    this.init();
  }
  async init() {
    const id = this.productId;
    try {
      const list = await getProductBaseCfg(id);
      this.form.productSign = list.identification;
      this.form.productType = list.idxSoftwareVersion;
      this.form.version = list.idxSoftwareVersion;
      const service = new UploadService('/oss/iot/oss');
      const array = await service.getAuth(list.picture);
      this.form.images = list.picture.map((v, index) => {
        return {
          uid: index,
          name: `picture${index}`,
          status: 'done',
          url: array[index],
          ossPath: v,
        };
      });
      this.form.remark = list.remark;
      const result = [];
      list.relatedFiles.forEach(v => {
        const fItem = this.form.settingFiles.find(
          item => item.fileName === v.fileName,
        );
        if (!fItem) {
          result.push({
            pkId: v.id !== '' ? v.id : undefined,
            fileName: v.fileName,
            fileUrl: v.fileUrl,
            menuName: v.menuName,
            canEdit: false,
            showDelete: v.relatedFilesEnum === 'CUSTOMIZE' ? true : undefined,
          });
        } else {
          Vue.set(fItem, 'fileUrl', v.fileUrl);
          Vue.set(fItem, 'pkId', v.id);
        }
      });
      this.form.settingFiles.push(...result);
      this.form.routeFileName = list.accessRouting;
    } catch {
      return false;
    }
  }

  async getProductInfos() {
    try {
      const result = await getProductInfo();
      result.forEach(v => {
        const o1 = {
          id: v.pkId,
          label: v.productType,
        };
        this.productTypes.push(o1);
        const o2 = {
          id: v.pkId,
          label: v.softwareVersion,
        };
        this.productVersion.push(o2);
      });
    } catch {
      return false;
    }
  }

  handleProductType(v) {
    this.form.productType = v;
    this.form.version = v;
  }
  async saveBaseCfg() {
    this.$refs.form.validate(async valid => {
      if (valid) {
        const params = {
          id: this.productId,
          identification: this.form.productSign,
          idxSoftwareVersion: this.form.productType,
          picture: this.form.images.map(v => v.ossPath),
          baseRelatedFiles: this.form.settingFiles,
          remark: this.form.remark,
          accessRouting: this.form.routeFileName,
        };
        try {
          await saveProductBaseCfg(params);
          this.$message.success('保存成功');
          this.$emit('refreshInfo');
          await this.init();
        } catch {
          return false;
        }
      }
    });
  }
}
</script>

<style lang="less" scoped>
.settingsBoxWrapper {
  display: flex;
  .baseSetBox {
    margin-right: 40px;
    width: 400px;
  }
  .settingsDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .settingsBtn {
      // border: 1px solid #eee;
      margin-left: 5px;
    }
  }
  .hFlex {
    display: flex;
  }
  .addFileBtn {
    cursor: pointer;
  }
  .textTips {
    color: var(--font-color-secondary);
  }
}
.card {
  border: 1px solid var(--border);
  box-shadow: 0 0 6px 0 var(--shadow);
  border-radius: 4px;
  padding: 20px 0;
  height: 580px;
  position: relative;
}
.form {
  height: 538px;
  padding: 0 20px;
  overflow-y: auto;
}
.button {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
</style>
