<template>
  <div>
    <a-row :gutter="20">
      <a-col :span="8">
        <div :class="$style.card">
          <div :class="$style.leftImg">
            <img :src="tableDefaultImg" alt="" />
          </div>
          <div>
            <div :class="$style.table">
              <a-row :class="$style.row1">
                <a-col :class="$style.border" style="height: 65px">
                  <div :class="$style.variable">变量</div>
                  <div :class="$style.line" />
                  <div :class="$style.step">步骤</div>
                </a-col>
              </a-row>
              <a-row :class="$style.row2">
                <a-col
                  :class="[$style.imgTitle, $style.border]"
                  :span="4"
                  v-for="(item, idx) in tableData"
                  :key="idx"
                >
                  <x-oss-image
                    v-if="item.paramsIcon !== ''"
                    basePath="/oss/iot/oss"
                    :ossPath="item.paramsIcon"
                    style="width: 30px; height: 30px;border-radius: 2px"
                  />
                  <span v-if="item.paramsUnit !== ''">
                    {{ item.paramsUnit }}
                  </span>
                </a-col>
              </a-row>
            </div>
            <div
              v-for="item in 5"
              :key="item"
              :class="$style.table"
              style="margin-top: 0;"
            >
              <a-row :class="$style.row1">
                <a-col :class="[$style.border, $style.index]">
                  <span>{{ item }}</span>
                </a-col>
              </a-row>
              <a-row :class="$style.row2">
                <a-col
                  :class="[$style.imgTitle, $style.border]"
                  style="height: 55px"
                  :span="4"
                  v-for="(item, idx) in tableData"
                  :key="idx"
                >
                  <x-icon
                    v-if="item.paramsIcon !== ''"
                    type="tc-icon-xiugai"
                    :class="$style.iconStyle"
                  />
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="16">
        <div :class="$style.card">
          <a-form-model ref="form" :model="form" layout="vertical">
            <a-row :gutter="20">
              <a-col :span="10">
                <a-form-model-item label="步骤数量">
                  <a-input-number
                    v-model="form.stepNumber"
                    :min="1"
                    :max="5"
                    :disabled="true"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="14">
                <a-form-model-item label="关联文件">
                  <a-input
                    v-model="form.associatedFiles"
                    :disabled="true"
                    style="width: 240px"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
          <a-form-model-item>
            <div slot="label">
              <span>参数设置</span>
              <a-button
                v-if="showAddTabFlag"
                :class="$style.addParamter"
                type="primary"
                @click="add"
              >
                添加参数
              </a-button>
            </div>
            <div :class="$style.tabs">
              <a-tabs
                v-model="activeKey"
                :hideAdd="true"
                type="editable-card"
                @edit="onEdit"
                @change="tabClick"
              >
                <a-tab-pane
                  v-for="item in panes"
                  :key="item.id"
                  :tab="item.paramsName"
                  :closable="panes.length !== 1"
                >
                  <Parameter
                    :parameterData="item"
                    @editFlag="getEditFlag"
                    @saveAll="saveAll"
                    @paramId="getParamId"
                  />
                </a-tab-pane>
              </a-tabs>
            </div>
          </a-form-model-item>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import Parameter from './parameter';
import { getRecipeCfg } from '@/services/oven/product';
import { v4 as uuidv4 } from 'uuid';
import {
  saveRecipeCfg,
  getMenuFileList,
  getIotOssHost,
} from '@/services/oven/product';
import { UploadService } from '@triascloud/services';

@Component({
  components: {
    Parameter,
  },
})
export default class RecipeSetting extends Vue {
  @Prop({ type: String, default: '' }) productId;
  form = {
    stepNumber: '5',
    associatedFiles: '',
    associatedFilId: '',
  };
  // 'https://img2.baidu.com/it/u=1791975692,3649003909&fm=26&fmt=auto'
  tableData = [];
  showAddTabFlag = true;
  @Watch('panes.length', { immediate: true })
  changeWidth(val) {
    this.showAddTabFlag = val < 6;
  }
  async mounted() {
    await this.init();
    await this.getList();
  }
  staticOssHead = '';
  async init() {
    try {
      this.staticOssHead = await getIotOssHost();
    } catch {
      return false;
    }
  }
  get tableDefaultImg() {
    return `${this.staticOssHead}/common/static/images/kolblog.png`;
  }
  async changeOssToUrl(list) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const result = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].startsWith('common/static')) {
        const url = `${this.staticOssHead}/${list[i]}`;
        result.push(url);
      } else {
        const url = await customService.getAuth(list[i]);
        result.push(url);
      }
    }
    return result;
  }
  filelist = [];
  panes = [];
  activeKey = '';
  async getList() {
    try {
      const list = await getRecipeCfg(this.productId);
      this.filelist = await getMenuFileList(this.productId);
      if (!list) {
        for (let i = 0; i < 6; i++) {
          this.tableData.push({
            id: '',
            paramsIcon: '',
            paramsUnit: '',
          });
        }
        return;
      }
      this.form.stepNumber = list.stepNumber;
      const idx = this.filelist.findIndex(v => v.id === list.associatedFiles);
      this.form.associatedFiles = this.filelist[idx].fileName;
      this.form.associatedFilId = list.associatedFiles;
      const ossPathArr = list.paramsSettings.map(v => v.paramsIcon);
      const ossPath = await this.changeOssToUrl(ossPathArr);
      this.panes = list.paramsSettings.map((v, index) => {
        return {
          ...v,
          step: v.step === -1 ? 1 : v.step,
          paramsIcon: [
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: ossPath[index],
              ossPath: v.paramsIcon,
            },
          ],
        };
      });
      this.activeKey = list.paramsSettings[0].id;
      this.tableData = list.paramsSettings;
      if (this.tableData.length < 6) {
        const n = 6 - this.tableData.length;
        for (let i = 0; i < n; i++) {
          const o = {
            id: '',
            paramsIcon: '',
            paramsUnit: '',
          };
          this.tableData.push(o);
        }
      }
    } catch {
      return false;
    }
  }
  onEdit(targetKey, action) {
    this[action](targetKey);
  }
  tabClick(v) {
    if (this.activityId !== v && this.editFlag) {
      this.$message.error('请先保存');
      this.activeKey = this.activityId;
    } else {
      this.activeKey = v;
    }
  }
  add() {
    if (this.editFlag) {
      this.$message.error('请先保存');
      return;
    }
    const panes = this.panes;
    let n = panes.length;
    const activeKey = `参数${++n}`;
    const uuid = uuidv4();
    panes.push({
      id: uuid,
      componentStyle: 'DOWN_PULL',
      min: 100,
      showFormat: 'INT',
      max: 1000,
      paramsUnit: 'INT',
      paramsIcon: [],
      step: 1,
      paramsName: activeKey,
    });
    this.activeKey = uuid;
  }
  remove(targetKey) {
    let activeKey = this.activeKey;
    let lastIndex;
    this.panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const panes = this.panes.filter(pane => pane.id !== targetKey);
    if (panes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        activeKey = panes[lastIndex].id;
      } else {
        activeKey = panes[0].id;
      }
    }
    this.panes = panes;
    const idx = this.tableData.findIndex(v => v.id === targetKey);
    this.tableData.splice(idx, 1);
    this.tableData.push({
      id: '',
      paramsIcon: '',
      paramsUnit: '',
    });
    this.activeKey = activeKey;
  }
  editFlag = false;
  getEditFlag(v) {
    this.editFlag = v;
  }
  activityId = '';
  getParamId(v) {
    this.activityId = v;
  }
  async getFormData(list) {
    const id = list.id;
    const idx = this.tableData.findIndex(v => v.id === id);
    const o = {
      ...list,
      paramsIcon: list.paramsIcon[0].ossPath,
      paramsUnit: list.paramsUnit,
    };
    if (idx > -1) {
      this.tableData.splice(idx, 1, o);
    } else {
      const index = this.tableData.findIndex(v => v.id === '');
      this.tableData.splice(index, 1, o);
    }
  }
  async saveAll(list) {
    await this.getFormData(list);
    const params = {
      id: this.productId,
      kolbRecipeSettingsJson: {
        associatedFiles: this.form.associatedFilId,
        stepNumber: this.form.stepNumber,
        paramsSettings: this.tableData
          .map(v => {
            if (v.id !== '') {
              return {
                ...v,
                paramsIcon: Array.isArray(v.paramsIcon)
                  ? v.paramsIcon[0].ossPath
                  : v.paramsIcon,
              };
            }
          })
          .filter(v => v !== null && v !== undefined && v !== ''),
      },
    };
    try {
      await saveRecipeCfg(params);
      this.$message.success('保存成功');
      await this.getList();
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.card {
  border: 1px solid var(--border);
  box-shadow: 0 0 6px 0 var(--shadow);
  border-radius: 4px;
  padding: 20px;
  height: 580px;
}
.img {
  width: 120px;
  height: 120px;
}
.addParamter {
  margin-left: 10px;
}
.tabs {
  margin-top: 10px;
}
:global {
  .ant-btn-primary {
    height: 32px;
  }
  .ant-table-thead > tr > th {
    background: none;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    background: var(--table-row-stripe);
    border-color: var(--form-border);
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background: var(--inline-menu-active);
    border-color: var(--form-border);
    border-bottom: var(--inline-menu-bg);
  }
}
.leftImg {
  width: 100%;
  height: 180px;
  border-radius: 5px;
  background-color: #ddd;
  line-height: 175px;
  text-align: center;
}
.table {
  margin-top: 20px;
  overflow-x: auto;
  display: flex;
  align-items: center;
  .row1 {
    width: 15%;
    .variable {
      position: absolute;
      top: 5px;
      right: 8px;
      color: var(--primary);
    }
    .line {
      width: 1px;
      height: 30px;
      background-color: var(--primary);
      position: absolute;
      transform: rotate(-60deg);
      top: 12px;
      left: 50%;
    }
    .step {
      position: absolute;
      left: 15px;
      bottom: 8px;
      color: var(--primary);
    }
  }
  .row2 {
    width: 85%;
  }
  .imgTitle {
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .border {
    border-right: 1px solid var(--form-border);
    border-bottom: 1px solid var(--form-border);
  }
  .index {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iconStyle {
    color: var(--primary);
  }
}
</style>
